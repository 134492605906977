/**
 * 证书监控 中文版
 * 页面路由 https://certbase.com/certhub/monitor
 */

 // Banner
 const banner = {
  title: '证书监控',
  summary: 'SSL/TLS证书监控工具可帮助您轻松监控SSL/TLS证书过期。捕获有关证书颁发机构，证书所有者，签发日期，到期日期和到期日期的信息。',
  link: 'https://app.certbase.com/sign-up',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_monitor.svg'
}

// 证书监控说明
const manage = {
  title: '证书监控使您的工作流程自动化',
  options: [
    {
      title: '监控网站连接状态',
      summary: '云端多节点定期自动扫描访问您的网站，检测证书状态。'
    },
    {
      title: '证书有效期监控',
      summary: '证书监控针对不同有效期的证书，实施分级管理，可有效地解决大量不同有效期证书难以管理的难题。'
    },
    {
      title: '异常故障告警',
      summary: '监控证书吊销、到期、OCSP无法连接等异常状态，及时短信或者邮件通知。'
    }
  ]
}

// 特性
const features = {
  title: '证书监控的特点',
  options: [
    [
      // 若是图片填充填写图片URL 若不是保留空值
      { title: '安全评级', content: ['Certbase 证书监控对您部署的 HTTPS 网站进行综合检测，我们根据安全风险给出了 A+、A、A-、B、C、D、E、F、T 九个评级，您可以非常直观地了解 HTTPS 站点的安全状况。同时，证书监控还会根据评测结果，给出合理的改造建议。'], img: '' }, // 左
      { title: '证书品牌', content: ['证书监控支持多种品牌证书的管理，只要您部署的是全球可信的证书品牌，都可以在 SSL 证书监控上管理您的证书，避免因证书品牌而受限的困扰。'], img: '' } // 右
    ],
    [
      { title: '证书有效期', content: ['证书监控针对不同有效期的证书，实施分级管理，可有效地解决大量不同有效期证书难以管理的难题。证书监控分级管理有效期包含3个月以上、3个月内、2个月内、一个月内和已过期，使您的证书有效期管理工作变得清晰明了。'], img: '' },
      { title: '证书类型', content: ['证书监控对证书进行分类管理，不仅从有效期和品牌两个方面，还可根据证书审核类型的不同对证书进行分类管理，包括 EV、OV、DV 和未知，全方位多维度的管理您部署的证书。'], img: '' }
    ],
    [
      { title: 'SSL 漏洞', content: ['目前高危漏洞有8种，其中包括 DROWN 漏洞、FREAK 漏洞、OpenSSL Padding Oracle 攻击、Logjam 漏洞、OpenSSL CCS 注入漏洞、心血漏洞（Heartbleed）、POODLE 漏洞以及 CRIME 漏洞。证书监控对 SSL 协议上爆出的相关漏洞进行监控和统计，助力站点安全性，防患于未然。'], img: '' },
      { title: '合规监测', content: ['网站合法性是站点存在的前提，用户信任站点的基石。证书监控支持两个安全标准和规范监测站点的合规性：PCI DSS 第三方支付行业数据安全标准，以及 ATS 应用传输安全规范（PCI DSS 和 ATS 也被小程序、Android 等生态参考），协助您构建合规的 HTTPS 站点。'], img: '' }
    ]
  ]
}

// 步骤
const step = {
  title: '3步快速监控网站证书',
  link: 'https://app.certbase.com/sign-up', // 按钮点击前往地址
  linkText: '立即体验',
  options: [
    {
      img: '',
      summary: '创建发现监控网站证书任务'
    },
    {
      img: '',
      summary: '设置目标域名、IP地址以及对应的端口'
    },
    {
      img: '',
      summary: '设置定时任务定期自动扫描。'
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}

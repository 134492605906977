// const isProduct = process.env.NODE_ENV !== 'production'

const brands = [
  { name: 'alphassl', url: '/logo/alphassl.svg' },
  { name: 'bitcert', url: '/logo/bitcert.svg' },
  { name: 'certum', url: '/logo/certum.svg' },
  // { name: 'digicert', url: '/logo/digicert-n.svg' },
  { name: 'geotrust', url: '/logo/geotrust.svg' },
  { name: 'globalsign', url: '/logo/globalsign.svg' },
  { name: 'positivessl', url: '/logo/positivessl.svg' },
  { name: 'rapidssl', url: '/logo/rapidssl.svg' },
  { name: 'sectigo', url: '/logo/sectigo.svg' },
  { name: 'securesite', url: '/logo/securesite.svg' },
  { name: 'thawte', url: '/logo/thawte.svg' },
  { name: 'cfca', url: '/logo/cfca.svg' }
]

export default {
  "privateInfo": {
    "tel": "(852) 9795 4564"
  },
  "common": {
    "day": "Day",
    "year": "Year",
    "price": "Our price",
    "originalPrice": "CA price",
    "minute": "Minute",
    "currency": "Dollar",
    "currencySymbol": "$",
    "productName": "Product name",
    "trust": "Trust level",
    "issueTime": "Issue time",
    "validity": "Validity",
    "secures": "Secures",
    "publicKeyAlgorithm": "Public key algorithm",
    "symmetricEncryption": "Symmetric encryption",
    "publicKeyLength": "Public key length",
    "rootCa": "Root CA",
    "refundSince": "Money-back guarantee",
    "reIssue": "Certificate reissue",
    "supportMulti": "Multi-domain support (SAN)",
    "warranty": "Warranty",
    "buy": "Buy",
    "buyNow": "Buy Now",
    "login": "Sign in",
    "register": "Get started",
    "compatible": "Browser Recognition",
    "submit": "Submit",
    "danger": "Not Secure",
    "discount": "Discount",
    "privatekeyontoken": "Private key on token",
    "addDomains": "Additional domains",
    "dv": "domain vetting",
    "ov": "organization vetting",
    "ev": "extended validation"
  },
  "productType": {
    "email-signing": "Secure Email Certificates",
    "code-signing": "Code Signing Certificates",
    "ev-code-signing": "EV Code Signing Certificates",
    "document-signing": "Document Signing Certificates",
    "single-domain": "Single Domain SSL",
    "multi-domain": "Multi-Domain (SAN)",
    "wildcard": "Wildcard Certificates",
    "domain-validation": "Domain Validation",
    "organization-validation": "Business Validation",
    "extended-validation": "Extended Validation",
    "other": "Other Digital Certificates"
  },
  "navbar": {
    contactUs: 'Contact US',
    help: 'Help',
    login: 'Login',
    registe: 'Registe',
    "options": [
      {
        "path": "/ssl",
        "text": "SSL/TLS",
        "expand": "ssl"
      },
      {
        "path": "/cert",
        "text": "Digital Signing",
        "expand": "cert"
      },
      {
        "path": "/certhub",
        "text": "Certhub",
        "expand": "certhub"
      },
      {
        "path": "/partner-program",
        "text": "Partner",
        "expand": 'partner'
      },
    ],
    "expand": {
      "nav": [
        {
          "text": "Brands",
          "index": "brands"
        },
        {
          "text": "Domain Validation",
          "index": "domain-validation"
        },
        {
          "text": "Organization Validation",
          "index": "organization-validation"
        },
        {
          "text": "Extended Validation",
          "index": "extended-validation"
        },
        {
          "text": "Multi-Domain (SAN/UCC)",
          "index": "multi-domain"
        },
        {
          "text": "Wildcard Certificates",
          "index": "wildcard"
        },
        {
          "text": "Public IP Address SSL",
          "index": "public-ip-address"
        }
      ],
      "content": {
        "brands": [],
        "domain-validation": [],
        "recommend": [],
        "help": [
          {
            "title": "Installation",
            "options": [
              {
                "text": "DNS",
                "path": "/sectigo/dv"
              }
            ]
          }
        ],
        "other": [
          {
            "title": "Other",
            "options": [
              {
                "text": "Change password",
                "path": "/sectigo/dv"
              }
            ]
          }
        ]
      },
      "certs": []
    }
  },
  "footer": {
    "media": [
      {
        "icon": "/icon/support__1.svg",
        "title": "Fast Issuance",
        "content": "within 3-5 minutes"
      },
      {
        "icon": "/icon/support__2.svg",
        "title": "Price Match",
        "content": "100% Guarantee"
      },
      {
        "icon": "/icon/support__3.svg",
        "title": "Free SSL",
        "content": "90-day for free"
      },
      {
        "icon": "/icon/support__4.svg",
        "title": "Money Back",
        "content": "30-day guarantee"
      },
      {
        "icon": "/icon/support__4.svg",
        "title": "24/7 Service Support",
        "content": "Multichannel Customer Service"
      }
    ],
    "aside": [
      {
        "label": "(852) 9795 4564",
        "icon": "ri-phone-line"
      },
      {
        "label": "(852) 9795 4564",
        "icon": "ri-smartphone-line"
      },
      {
        "label": "Suite 1503, 15/F Carnival Comm BLDG 18 Java RD North Point, Hong Kong, China",
        "icon": "ri-map-pin-line"
      },
      {
        "label": "Working Time：Monday-Sunday",
        "icon": "ri-time-line"
      },
      {
        "label": "support@sslcertificateshop.com",
        "icon": "ri-mail-send-line"
      }
    ],
    "content": [
      {
        "title": "SSL/TLS",
        "options": [
          {
            "path": "/ssl/domain-validation",
            "text": "Domain Validation"
          },
          {
            "path": "/ssl/organization-validation",
            "text": "Organization Validation"
          },
          {
            "path": "/ssl/extended-validation",
            "text": "Extended Validation"
          },
          {
            "path": "/ssl/multi-domain",
            "text": "Multi-Domain (SAN)"
          },
          {
            "path": "/ssl/wildcard",
            "text": "Wildcard Certificates"
          },
          {
            "path": "/digital-signing/code-signing",
            "text": "Windows Code Signing"
          },
          {
            "path": "/digital-signing/document-signing",
            "text": "Document Signing (AATL)"
          },
          {
            "path": "/digital-signing/email-signing",
            "text": "Email Signing (S/MIME)"
          }
        ]
      },
      {
        "title": "Brands",
        "options": [
          {
            "path": "/brand/securesite",
            "text": "Secure Site"
          },
          {
            "path": "/brand/digicert",
            "text": "DigiCert"
          },
          {
            "path": "/brand/globalsign",
            "text": "GlobalSign"
          },
          {
            "path": "/brand/geotrust",
            "text": "GeoTrust"
          },
          {
            "path": "/brand/sectigo",
            "text": "Sectigo"
          },
          {
            "path": "/brand/thawte",
            "text": "Thawte"
          },
          {
            "path": "/brand/rapidssl",
            "text": "RapidSSL"
          },
          {
            "path": "/brand/alphassl",
            "text": "AlphaSSL"
          }
        ]
      },
      {
        "title": "Help Center",
        "options": [
          {
            "path": "",
            "text": "Announcements"
          },
          {
            "path": "",
            "text": "Installation "
          },
          {
            "path": "",
            "text": "Troubleshootings"
          },
          {
            "path": "",
            "text": "FAQ"
          },
          {
            "path": "",
            "text": "Help for Code Signing"
          },
          {
            "path": "",
            "text": "Website Security Optimization"
          }
        ]
      },
      {
        "title": "About",
        "options": [
          {
            "path": "/about-us",
            "text": "About Certbase"
          },
          {
            "path": "/contact-us",
            "text": "Contact us"
          },
          {
            "path": "/partner-program",
            "text": "Partners"
          },
          {
            "path": "",
            "text": "Blog"
          },
          {
            "path": "",
            "text": "Account"
          },
          {
            "path": "",
            "text": "Trust Center"
          }
        ]
      },
      {
        "title": "Disclaimer",
        "options": [
          {
            "path": "",
            "text": "We sell digital certificates from leading certificate authorities for more than 10 years."
          },
          {
            "path": "",
            "text": "We accept payments by card, PayPal, Alipay, Wechat Pay, Bank Transfer."
          },
          {
            "path": "",
            "text": "Prices include VAT unless otherwise stated."
          },
          {
            "path": "",
            "text": "All trademarks displayed on this web site are the exclusive property of the respective holders."
          }
        ]
      }
    ],
    "signature": [
      {
        "brand": "geotrust",
        "coverImgUrl": "/images/geotrustsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "globalsign",
        "coverImgUrl": "/images/globalsign-site-seal.svg",
        "signature": ""
      },
      {
        "brand": "rapidssl",
        "coverImgUrl": "/images/rapidsslsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "sectigo",
        "coverImgUrl": "/images/sectigositeseal.svg",
        "signature": ""
      },
      {
        "brand": "thawte",
        "coverImgUrl": "/images/thawtesiteseal.svg",
        "signature": ""
      },
      {
        "brand": "digicert",
        "coverImgUrl": "/images/digicert-n.svg",
        "signature": ""
      },
      {
        "brand": "norton",
        "coverImgUrl": "/images/nortonsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "securesite",
        "coverImgUrl": "/images/nortonsiteseal.svg",
        "signature": ""
      }
    ],
    "copyright": [
      "© $year Certbase™. Base Technology Limited Registered in Hong Kong, China Company No. 2806295",
      "Certbase™ is a trademark of Cloud Internet Security Limited in China and Worldwide. All Rights Reserved"
    ],
    "license": [
      {
        "text": "Terms",
        "path": "/service-agreement"
      },
      {
        "text": "Privacy",
        "path": "/privacy-policy"
      },
      {
        "text": "Member",
        "path": "/member-aggreement"
      },
      {
        "text": "Refund",
        "path": "/refund-policy"
      }
    ],
    "declareContent": {
      "title": "Disclaimer",
      "content": "All trademarks displayed on this web site are the exclusive property of the respective holders.",
      "options": [
        {
          "path": "",
          "text": "We sell digital certificates from leading certificate authorities for more than 10 years."
        },
        {
          "path": "",
          "text": "We accept payments by card, PayPal, Alipay, Wechat Pay, Bank Transfer."
        },
        {
          "path": "",
          "text": "Prices include VAT unless otherwise stated."
        },
        {
          "path": "",
          "text": "All trademarks displayed on this web site are the exclusive property of the respective holders."
        }
      ]
    },
    "options": [
      [
        {
          "title": "GlobalSign",
          "options": [
            {
              "alias": "globalsign-domainssl",
              "title": "DomainSSL",
              "href": ""
            },
            {
              "alias": "globalsign-domainssl-wildcard",
              "title": "DomainSSL Wildcard",
              "href": ""
            },
            {
              "alias": "globalsign-organizationssl",
              "title": "OrganizationSSL",
              "href": ""
            },
            {
              "alias": "globalsign-extendedssl",
              "title": "ExtendedSSL",
              "href": ""
            },
            {
              "alias": "globalsign-code-signing",
              "title": "Code Signing",
              "href": ""
            },
            {
              "alias": "globalsign-ev-code-signing",
              "title": "EV Code Signing",
              "href": ""
            },
            {
              "alias": "",
              "title": "Document Signing",
              "href": ""
            }
          ]
        },
        {
          "title": "AlphaSSL",
          "options": [
            {
              "alias": "alphassl",
              "title": "AlphaSSL",
              "href": ""
            },
            {
              "alias": "alphassl-wildcard",
              "title": "AlphaSSL Wildcard",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "DigiCert",
          "options": [
            {
              "alias": "digicert-basic-ov",
              "title": "Basic OV",
              "href": ""
            },
            {
              "alias": "digicert-basic-ev",
              "title": "Basic EV",
              "href": ""
            },
            {
              "alias": "digicert-code-signing",
              "title": "Code Signing",
              "href": ""
            },
            {
              "alias": "digicert-ev-code-signing",
              "title": "EV Code Signing",
              "href": ""
            },
            {
              "alias": "digicert-document-signing-org-2000",
              "title": "Document Signing",
              "href": ""
            },
            {
              "alias": "digicert-smime-premium",
              "title": "S/MIME Premium",
              "href": ""
            }
          ]
        },
        {
          "title": "RapidSSL",
          "options": [
            {
              "alias": "rapidssl",
              "title": "RapidSSL",
              "href": ""
            },
            {
              "alias": "rapidssl-wildcard",
              "title": "RapidSSL Wildcard",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "Secure Site",
          "options": [
            {
              "alias": "secure-site",
              "title": "Secure Site",
              "href": ""
            },
            {
              "alias": "secure-site-ev",
              "title": "Secure Site EV",
              "href": ""
            }
          ]
        },
        {
          "title": "Thawte",
          "options": [
            {
              "alias": "thawte-ssl-123",
              "title": "SSL 123",
              "href": ""
            },
            {
              "alias": "thawte-web-server",
              "title": "Web Server",
              "href": ""
            },
            {
              "alias": "thawte-web-server-ev",
              "title": "Web Server EV",
              "href": ""
            }
          ]
        },
        {
          "title": "GeoTrust",
          "options": [
            {
              "alias": "geotrust-standard-dv",
              "title": "Standard DV",
              "href": ""
            },
            {
              "alias": "geotrust-true-businessid",
              "title": "True BusinessID OV",
              "href": ""
            },
            {
              "alias": "geotrust-true-businessid-ev",
              "title": "True BusinessID EV",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "Secure Site Pro",
          "options": [
            {
              "alias": "secure-site-pro",
              "title": "Secure Site Pro",
              "href": ""
            },
            {
              "alias": "secure-site-pro-ev",
              "title": "Secure Site Pro EV",
              "href": ""
            }
          ]
        },
        {
          "title": "PositiveSSL",
          "options": [
            {
              "alias": "positivessl-dv",
              "title": "PositiveSSL",
              "href": ""
            }
          ]
        },
        {
          "title": "Sectigo",
          "options": [
            {
              "alias": "sectigo-dv",
              "title": "Sectigo DV",
              "href": ""
            },
            {
              "alias": "sectigo-ov",
              "title": "Sectigo OV",
              "href": ""
            },
            {
              "alias": "sectigo-ev",
              "title": "Sectigo EV",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "Godaddy",
          "options": [
            {
              "alias": "godaddy-dv",
              "title": "Godaddy DV"
            },
            {
              "alias": "godaddy-wild",
              "title": "Godaddy Wildcard"
            },
            {
              "alias": "godaddy-multi",
              "title": "Godaddy Multi-Domain"
            }
          ]
        },
        {
          "title": "CFCA",
          "options": [
            {
              "alias": "cfca-ov",
              "title": "CFCA OV"
            },
            {
              "alias": "cfca-ev",
              "title": "CFCA EV"
            },
            {
              "alias": "cfca-sm",
              "title": "CFCA SM2"
            }
          ]
        }
      ]
    ]
  },
  "codeSigning": {
    "title": "You can use code signing certificates on these platforms",
    "summary": [
      "Choose the DigiCert Code Signing EV certificate for your application and platform."
    ],
    "table": [
      {
        "offset": -444,
        "title": "Microsoft® Authenticode®",
        "content": "The certificate is intended for signing .exe, .cab, .dll, .ocx, .msi, .xpi, and .xap files. It is required for Microsoft Windows Logo certification."
      },
      {
        "offset": -744,
        "title": "Java",
        "content": "Certificate designed for Java applications for desktop and mobile devices. Signs .jar files and is compatible with the Java Runtime Environment (JRE)."
      },
      {
        "offset": -894,
        "title": "Microsoft® Office and VBA",
        "content": "The certificate is intended for signing VBA objects and macros. Suitable for Microsoft Office and third-party VBA applications."
      },
      {
        "offset": -1050,
        "title": "Adobe® AIR®",
        "content": "Digitally signs .air or .airi files. Necessary for all AIR-based applications."
      },
      {
        "offset": -600,
        "title": "Android",
        "content": "The certificate for signing .apk files for Android."
      },
      {
        "offset": -1194,
        "title": "Microsoft Windows Phone",
        "content": "Digitally signs the code for Windows Phone and Xbox 360. Signing is required to publish the application to the Microsoft App Hub."
      },
      {
        "offset": -1344,
        "title": "Qualcomm BREW",
        "content": "Digitally signs the code for BREW platform."
      }
    ]
  },
  "home": {
    brands,
    "pageTitle": "SSL/TLS certificates from world's premium brands with better support",
    "contents": "SSL/TLS certificates only from certbase.com. We can advise you on selecting them, help you with the verification process, and finally help you deploy your SSL/TLS certificates. Our team of experts is ready to help you any time.",
    "banner": {
      "title": "Online security for everyone",
      "summary": "We will secure your data and increase your credibility. We will provide you with a certificate for your website, an electronic signature for your emails, documents, and applications. Ensure email security, protect user terminals and data, identify phishing websites and counterfeit websites, verify the identity of software vendors, protect the security of mobile apps, and manage the authorization of entities (users, programs) to enhance your credit.",
      "btn": [
        "Choose Certificate",
        "Chat Now"
      ]
    },
    "fixedBanner": [
      {
        "icon": "/icon/service__input_domain.svg",
        "title": "Select Cert & Domain",
        "subtitle": "SSL/TLS & Signing Certificates on Demand"
      },
      {
        "icon": "/icon/service__create_order.svg",
        "title": "CSR & Validation",
        "subtitle": "Auto Generate CSR with DV, OV, EV Validation"
      },
      {
        "icon": "/icon/service__download.svg",
        "title": "Certificate Issused",
        "subtitle": "Install Certificate & Get Site Secured"
      },
      {
        "icon": "/icon/service__ Hardware.svg",
        "title": "Certificate Monitor",
        "subtitle": "SSL Certificate Management On Autopilot"
      }
    ],
    "subbanner": {
      "left": {
        "icon": "/icon/industry__medical.svg",
        "iconText": "Medical Health"
      },
      "right": [
        {
          "icon": "/icon/industry__ai.svg",
          "iconText": "Medical Health"
        },
        {
          "icon": "/icon/industry__ai.svg",
          "iconText": "Education"
        },
        {
          "icon": "/icon/industry__finance.svg",
          "iconText": "Financial"
        },
        {
          "icon": "/icon/industry__service.svg",
          "iconText": "Securities"
        },
        {
          "icon": "/icon/industry__iot.svg",
          "iconText": "IoT"
        },
        {
          "icon": "/icon/industry__app.svg",
          "iconText": "Insurance"
        },
        {
          "icon": "/icon/industry__traffic.svg",
          "iconText": "E-commerce"
        },
        {
          "icon": "/icon/industry__send_data.svg",
          "iconText": "Payments"
        },
        {
          "icon": "/icon/industry__append_1.svg",
          "iconText": "Government"
        },
        {
          "icon": "/icon/industry__append_2.svg",
          "iconText": "Internet"
        }
      ]
    },
    "product": {
      "title": "SSL/TLS Certificates",
      "btn": "Buy Now",
      "data": [
        {
          "title": "For Common Customers",
          "subtitle": "Domain Certificate Suitable",
          "summary": "A basic certificate for common personal site users who do not have high demands on credibility. ",
          "price": "from $49",
          "titleLink": "/brand/globalsign/globalsign-domainssl"
        },
        {
          "title": "For Business",
          "subtitle": "Best Selling Certificate",
          "summary": "A springboard for any business website. A trusted certificate contains verified information for your business.",
          "price": "from $199",
          "titleLink": "/brand/globalsign/globalsign-domainssl"
        },
        {
          "title": "Money Related Websites",
          "subtitle": "The Most Trusted Certificate Suitable For",
          "summary": "The highest level of trust and verification. Your company name is visible to visitors in green next to your website address.",
          "price": "from $299",
          "titleLink": "/brand/globalsign/globalsign-domainssl"
        }
      ]
    },
    "whatTls": {
      "title": "What Are SSL/TLS Certificates For?",
      "topTitle": "Advantages of The Certificate",
      "more": "learn more",
      "data": [
        {
          "title": "Encryption",
          "imgUrl": "/icon/encrypt.svg",
          "summary": "The certificate protects encryption against eavesdropping, theft, and misuse of information."
        },
        {
          "title": "Authentication",
          "imgUrl": "/icon/user-auth.svg",
          "summary": "Thanks to the certificate, you know which server you are communicating with."
        },
        {
          "title": "Data Integrity",
          "imgUrl": "/icon/full-data.svg",
          "summary": "With encryption, data cannot be changed during transmission."
        }
      ]
    },
    "certifyUs": {
      "title": "Why Certify With Us?",
      "topTitle": "Advantage of Certbase",
      "more": "learn more",
      "data": [
        {
          "path": "/about-us",
          "imgUrl": "/icon/publisher.svg",
          "summary": "We are the largest issuer of SSL/TLS certificates"
        },
        {
          "path": "/references",
          "imgUrl": "/icon/partner.svg",
          "summary": "The greatest companies trust us. We are reliable partner."
        },
        {
          "path": "/contact-us",
          "imgUrl": "/icon/code.svg",
          "summary": "We provide NON-STOP technical support"
        },
        {
          "path": "/bank-details-and-payment-methods",
          "imgUrl": "/icon/payment.svg",
          "summary": "Fast online payments. Save time on payment."
        }
      ]
    },
    "getCert": {
      "title": "Get Your SSL/TLS Certificates Today",
      "data": [
        {
          "title": "Use our wizard for SSL/TLS certificatew selection",
          "content": "and answer 3 simple questions. The wizard will help you to choose the most suitable certificate for your project. You can also compare the certificate details on the comparison page."
        },
        {
          "title": "We will help you to deploy your SSL/TLS certificatew",
          "content": "Our experts with Web Security Expert certification and years of experience are at your service on weekdays during our working hours. Customer support for certbase.com internet services is available 24/7."
        },
        {
          "title": "Your visitors enjoy the benefits of a secure website",
          "content": "With an SSL/TLS certificate from Certbase, visitors to your site are protected. In addition to securing data against eavesdropping, the certificate also fulfils the authentication function. The green EV certificate bar at first glance reveals the identity of the server owner, so they can see who they are communicating with immediately."
        }
      ],
      "banner": {
        "title": "Get Your SSL/TLS Certificates Today",
        "btn": "Buy Now"
      }
    },
    "contrast": {
      "title": "A Comparison of DV, OV and EV Certificates",
      "theads": [
        "Validation Type",
        "Organization Validation",
        "Domain Validation",
        "Extended Validation"
      ],
      "options": [
        [
          "Suitable for",
          "enterprise applications (OA, CRM, ERP, HRM, etc.), corporate website, e-commerce",
          "small and micro enterprises/API services/personal websites",
          "financial platforms, large and medium enterprises, and government agencies"
        ],
        [
          "Scene Features",
          "Improve system security, ensure that sensitive information is not hijacked, and enhance corporate integrity and user trust",
          "Use SSL certificate for high-strength encryption of information transmission, which can effectively prevent information hijacking",
          "Guarantee information security and website credibility to the greatest extent, standard configuration for large websites"
        ],
        [
          "Validation",
          "Enterprise Identity verification",
          "Verify domain ownership",
          "Highest verification level"
        ],
        [
          "Browser View",
          "Standard padlock with corporate information displayed in the certificate",
          "Standard padlock with domains",
          "Green padlock in some browsers with corporate information"
        ],
        [
          "Brwoser compatible w/",
          "all major browsers",
          "all major browsers",
          "all major browsers"
        ],
        [
          "Server license",
          "Unlimited",
          "Unlimited",
          "Unlimited"
        ],
        [
          "Public key algorithm",
          "RSA & ECC",
          "RSA & ECC",
          "RSA & ECC"
        ],
        [
          "Issuing time",
          "1-2 Days",
          "10 minutes",
          "1-3 Days"
        ],
        [
          "Maximum validity1",
          "6 years",
          "6 years",
          "6 years"
        ],
        [
          "Money Back Guarantee",
          "30 Days",
          "30 Days",
          "30 Days"
        ],
        [
          "Warranty (USD)",
          "up to $1 250 000",
          "up to $50,0000",
          "up to $1 750 000"
        ]
      ]
    },
    "features": {
      "title": "and Modernizing Your Certificate Management",
      "data": [
        {
          "title": "Multi-year TLS certificates",
          "icon": "/icon/platform__1.svg",
          "summary": "Multi-year SSL/TLS certificates order simplifies the administration of certificates management and certificates renewal, ordering for more years will save you a lot of money."
        },
        {
          "title": "Web Security Made Simple",
          "icon": "/icon/platform__2.svg",
          "summary": "With one-click to download all formats of your certificates and push them into multi public clouds with one click more (such as Alibaba Cloud, Tencent Cloud, Amazon Web Service etc.)."
        },
        {
          "title": "World's Premium Brands",
          "icon": "/icon/platform__3.svg",
          "summary": "Connecting with the world's most trusted and famous third-party digital certificate authorities (CA) at home and abroad to meet customers' different preferences and bidding needs."
        },
        {
          "title": "Autopilot Certificate Management",
          "icon": "/icon/platform__4.svg",
          "summary": "Certificate management must be a priority for 2021. The risks are undeniable.汇智云安provides visibility, control and automation to keep up with the demands of digital certificate security."
        }
      ]
    }
  },
  "about": {
    "pageTitle": "About Certbase",
    "title": "About Certbase",
    "summary": [
      "We provide a comprehensive solution for securing your data",
      "Certbase™ is one of the largest providers of SSL certificates in the World and belongs to the Base Technology Limited company. Our aim is making use of World Wide Web safe and protected using various services we provide like SSL certificates, Anti-Malware tools and scanners, Site Seals. At present we provide Domain, Business, and Extended Validation certificates, Wildcard, Multi-Domain (SAN), Code Signing and S/MIME certificates."
    ],
    "banner": {
      "title": "The know-how of our success",
      "imgUrl": "/images/sslmarket_mapa_onas_UK.png",
      "summary": [
        {
          "title": "User intelligibility",
          "content": "The client should understand how the certificate and encryption work, but they should not be unnecessarily overwhelmed with detailed information that they don't even need to know."
        },
        {
          "title": "Partner CA reliability",
          "content": "We have always focused only on the highest quality products. We offer popular DigiCert， GlobalSign， Sectigo certificates and othersthe most famous certificate authorities in the world."
        },
        {
          "title": "User intelligibility",
          "content": "We have always focused only on the highest quality products. We offer popular DigiCert, GlobalSign, Sectigo, BitCert, GeoTrust, Thawte, RapidSSL certificates and others the most famous certificate authorities in the world."
        }
      ]
    },
    "contents": [
      {
        "layout": "",
        "src": "/logo/digicert.svg",
        "title": "We are a proud partner of the largest certification authorities in the world",
        "content": "We are Strategic Partner of Sectigo (formerly Comodo CA), partner of DigiCert, GlobalSign, Certum. We have large volumes and receive significant discounts from vendors, that gives us the availability to pass discounts to you and to offer the lowest prices possible. We sold more than 100,000 SSL certificates within 20,000 customers, operating 50+ resellers globally. We appreciate everyone for choosing our company and we would continue to improve our services and quality."
      },
      {
        "layout": "",
        "src": "/logo/globalsign.svg",
        "title": "GlobalSign Partner",
        "content": "GlobalSign is the leading provider of trusted identity and security solutions enabling businesses, large enterprises, cloud service providers and IoT innovators around the world to secure online communications, manage millions of verified digital identities and automate authentication and encryption. Its high-scale Public Key Infrastructure (PKI) and identity solutions support the billions of services, devices, people and things comprising the Internet of Everything (IoE)."
      },
      {
        "layout": "",
        "src": "/logo/sectigo.svg",
        "title": "Sectigo Partner",
        "content": "More than 600 people in Comodo team create Online Trust for individuals, small to medium businesses, e-merchants and large enterprises. Comodo provides Internet Security Software, Email Security & Messaging, Hosted DNS, SSL Certificates, PKI Management, Browsers and much more."
      },
      {
        "layout": "",
        "src": "/logo/digicert.svg",
        "title": "DigiCert Partner",
        "content": "The company was founded in early 1982 by computer scientists. Today it's one of the largest software companies in the world with more than 18,600 employees in 50 countries. DigiCert offers malware scanning and vulnerability assessment, the Norton Secured Seal, 24/7 support and express renewal. "
      },
      {
        "layout": "",
        "src": "/logo/geotrust.svg",
        "title": "GeoTrust Partner",
        "content": "More than 100,000 clients from over 150 countries trust GeoTrust solutions and let to conduct business and to secure online transactions over the Internet. Geotrust has a broad range of products, like domain validation, Business validation (Organization), Extended Validation SSL Certificates; that secures single domains, sub-domains, and SAN. "
      },
      {
        "layout": "",
        "src": "/logo/thawte.svg",
        "title": "Thawte Partner",
        "content": "Thawte protects transactions and identities in over 240 countries. VeriSign (Symantec) acquire Thawte in 2000, and now Thawte is a core member of DigiCert family of trust brands. More than 950,000 SSL Certificates and Code Signing certificates issued by Thawte since 1995. Thawte offers multilingual support via chat, by phone and email throughout the global workday. "
      },
      {
        "layout": "",
        "src": "/logo/rapidssl.svg",
        "title": "RapidSSL Partner",
        "content": "The mission of SSL Certificates from RapidSSL is to provide you an SSL certificate as fast as possible, that is why all processes are fully automated. No paperwork, all you need to get your SSL is to prove domain ownership via email. RapidSSL is owned and operated by GeoTrust."
      }
    ]
  },
  "hardware": {
    "pageTitle": "HSM",
    "title": "Our HSM Partner",
    "summary": "Our accumulated portfolio of awards and accreditatuions mean our trusted consultants are the leading web security experts in Europe who have satisfied numerous high profiled clients over the years. By choosing Certbase® as your HSMs providing specialist, your organization will gain the same comprehensive level of support we’ve become renowned for as well as privileged access to exclusive market leading benefits including:",
    "contents": [
      {
        "layout": "",
        "src": "/images/digicert_platinum_elite_partner.png",
        "title": "TLS Handshake Hardware Accelerator",
        "content": "The TLS handshake hardware accelerator is a secure connection engine that can be used to offload the compute intensive Public Key operations (Diffie-Helmann, Signature Generation and Verification).It combines a load dispatcher and a configurable amount of instances of the Public Key Crypto Engine (BA414EP) benefiting from all features supported (i.e. RSA/DH/DHE and ECDSA/ECDH/ECDHE/X.25519/X.448 and more). The efficient dispatching to several tenths of BA414EP instances helps reaching maximum system performance."
      },
      {
        "layout": "end",
        "src": "/images/digicert_platinum_elite_partner.png",
        "title": "Hardware Security Module (HSM) For Modern Systems",
        "content": "A hardware security module, or HSM, is a dedicated, standards-compliant cryptographic appliance designed to protect sensitive data in transit, in use, and at rest through the use of physical security measures, logical security controls, and strong encryption."
      },
      {
        "layout": "",
        "src": "",
        "title": "USB Hardware Encryption & Two-Factor Authentication Keys",
        "content": "USB dongles help secure your PC or laptop, preventing unauthorised access. They combine hardware-based authentication, public key cryptography to prevent unauthorised users from accessing compatible software and websites."
      }
    ]
  },
  "monitor": {
    "pageTitle": "Certhub",
    "banner": {
      "btn": "Get Started",
      "title": "Certhub Certificate Monitor",
      "summary": "Certhub is a cloud platform to automatically discover, organize, and track all SSL/TLS certificates across the enterprise."
    },
    "summary": {
      "title": "Summary of Certhub",
      "content": "It provides visual rating charts and interactive cross-brand certificate management dashboards, supports HTTPS security ratings, certificate brands, certificate validity periods, SSL vulnerabilities, PCI DSS & ATS compliance monitoring and centralized management; it can also timely pass exceptions Email, WeChat, and SMS notifications are provided, and renovation suggestions are given based on the evaluation results."
    },
    "feature": {
      "title": "Features of Certhub",
      "options": [
        {
          "layout": "",
          "src": "/images/42ca2031188e55aae650da8b577cbee9.svg",
          "title": "Security rating",
          "content": "Certificate monitoring performs comprehensive inspection on the HTTPS website you deploy. We have given nine ratings of A+, A, A-, B, C, D, E, F, and T based on the security risk. You can intuitively understand the HTTPS site Security situation. At the same time, the certificate monitoring will also give reasonable renovation suggestions based on the evaluation results."
        },
        {
          "layout": "end",
          "src": "/images/4e6988b05a5884f0b5720a1c2051f29b.svg",
          "title": "Certificate Authorities",
          "content": "Certificate monitoring supports the management of multiple brand certificates. As long as you deploy global trusted certificate brands, such as DigiCert, Secure Site, GlobalSign, BitCert, Sectigo, Certum etc., you can manage your certificates on SSL certificate monitoring to avoid the risk of certificate brands. Limited troubles."
        },
        {
          "layout": "",
          "src": "/images/63df11e875de7017acaa8ae74289f790.svg",
          "title": "Certificate validity",
          "content": "Certificate monitoring implements hierarchical management for certificates with different validity periods, which can effectively solve the difficult problem of a large number of certificates with different validity periods. The validity period of certificate monitoring hierarchical management includes more than 3 months, 3 months, 2 months, one month and expired, making your certificate validity management work clear."
        },
        {
          "layout": "",
          "src": "/images/ccc7efa10134bbbf6e043597beb0f0b7.svg",
          "title": "Certificate type",
          "content": "Certificate monitoring performs classification management of certificates, not only in terms of validity and brand, but also classification management of certificates according to different types of certificate audits, including EV, OV, DV and unknown, comprehensively and multi-dimensionally managing your deployed certificates"
        },
        {
          "layout": "",
          "src": "/images/e3543d40597c6f85ca16a829c382c840.svg",
          "title": "SSL vulnerability",
          "content": "There are 8 high-risk vulnerabilities, including DROWN vulnerability, FREAK vulnerability, OpenSSL Padding Oracle attack, Logjam vulnerability, OpenSSL CCS injection vulnerability, Heartbleed vulnerability, POODLE vulnerability and CRIME vulnerability. Certificate monitoring monitors and counts related vulnerabilities in the SSL protocol to help site security and prevent problems before it happends."
        },
        {
          "layout": "",
          "src": "/images/335d302c5e5f7cc1032392ef27214e2c.svg",
          "title": "Compliance monitoring",
          "content": "The legitimacy of the website is the prerequisite for the existence of the website, and the cornerstone of users' trust in the website. Certificate monitoring supports the compliance of two security standards and regulatory monitoring sites: PCI DSS, a third-party payment industry data security standard, and ATS application transmission security specification (PCI DSS and ATS are also referenced by applets, Android, etc.) to assist you Build a compliant HTTPS site."
        }
      ]
    },
    "scenes": {
      "title": "Power up your SSL certificate management",
      "content": "Built on design thinking principles, Certhub simplifies certificate lifecycle operations, reduces adoption time and streamlines digital transformation.",
      "tabbarController": [
        {
          "index": "detect",
          "label": "Detect"
        },
        {
          "index": "organize",
          "label": "Organize"
        },
        {
          "index": "analyze",
          "label": "Analyze"
        }
      ],
      "panelData": {
        "detect": {
          "coverImgUrl": "/images/certhub-check.svg",
          "summary": "Identify every certificate, known and unknown, with a permanent auto scan of your external and internal environments.",
          "list": [
            "Scan by a single domain, domain list, or IP range including up to 10,000 targets in one scan profile",
            "Find even more certificates by including subdomains",
            "Perform an internal scan by installing a Windows or Linux agent in just two clicks"
          ]
        },
        "organize": {
          "coverImgUrl": "/images/certbase-manage.svg",
          "summary": "Get enhanced visibility in one place. Filter, group and sort all your certificates to get the list exactly the way you want it.",
          "list": [
            "Auto update feature always keeps your inventory up-to-date.",
            "Nine conditional filters help you organize the data according to your needs",
            "Create custom certificate groups for further tracking to save you time"
          ]
        },
        "analyze": {
          "coverImgUrl": "/images/certbase-anly.svg",
          "summary": "Drill down through the system health from “general overview” to the “detailed card” on each digital certificate regardless of the issuer.",
          "list": [
            "Evaluate the entire landscape with seven interactive charts, and see the full picture, all on one screen",
            "Make informed decisions based on customized system health reports delivered right to your inbox",
            "Schedule report start date and frequency, and then add more recipients if needed"
          ]
        }
      }
    },
    "footer": {
      "left": {
        "btn": "Get Started",
        "title": "Start to use Certhub Certificate Monitor",
        "summary": "With few clicks, you will be a monitor nerd too."
      },
      "right": {
        "btn": "Contact Us",
        "title": "Contact Us",
        "summary": "We are offering customized pre-sale servier and full after sale technical service"
      }
    }
  },
  "partner": {
    "pageTitle": "Partner Program",
    "title": "Loyalty Discounts For汇智云安Customers",
    "subtitle": "Partner Program",
    "contents": [
      {
        "title": "",
        "content": [
          "Are you a company in the IT business? Do you provide IT services or security for your customers? We are a stable partner for your business. When you purchase multiple certificates, we automatically give your purchases a discount with no paperwork. You do not need to ask us!",
          "Our affiliate program is designed for IT businesses needing TLS certificates and security for their customers. For example companies creating websites, e-shops, intra/extranet solutions, as well as companies offering www services, IT solutions implementators (such as Exchange, Sharepoint, etc)."
        ]
      },
      {
        "title": "Basic information about Partner program",
        "content": [
          "Discount up to 20+ %",
          "Starting discount 5-10 %",
          "Higher discount if you buy more certificates",
          "the widest range of trusted TLS certificates on the market",
          "bonuses and rewards for active partners"
        ]
      },
      {
        "title": "How to become a partner",
        "content": [
          "Any person ordering TLS certificates on SSLmarket can become a partner. The partner discount amount is calculated from the turnover of active (not expired) TLS certificates in the customer account (without VAT).",
          "For business and partnership inquiries, please contact sales@certbase.com."
        ]
      }
    ],
    "range": {
      "title": "Move the pointer on the slider to see the amount of your discount:",
      "amountTips": "Turnover in USD:"
    },
    "discountTable": {
      "btn": "discount table",
      "title": [
        "Turnover in USD more than:",
        "Discount"
      ]
    }
  },
  "contact": {
    "pageTitle": "Contact us",
    "title": "Contact us",
    "tel": "177-0811-9188",
    "phone": "400-800-3563",
    "email": "support@sslcertificateshop.com",
    "address": "Suite 1503, 15/F Carnival Comm BLDG 18 Java RD North Point, Hong Kong, China",
    "work": "24/7 Live Help",
    "coverImgUrl": "/images/partner.svg",
    "summary": [
      "We are a DigiCert Certified Partner Platinum Elite. The highest degree of partnership with DigiCert Certification Authority demonstrates your TLS high competence and knowledge. With SSLmarket you get the best products and customer support on the market. Let's discuss your security needs!"
    ],
    "group": {
      "title": "SHORTCUTS",
      "options": [
        {
          "path": "",
          "text": "Bank details and payment methods"
        },
        {
          "path": "",
          "text": "About Base Technology Limited"
        },
        {
          "path": "",
          "text": "Who we are"
        },
        {
          "path": "",
          "text": "Our proofs"
        },
        {
          "path": "",
          "text": "Advanced Client Side Encryption"
        }
      ]
    },
    "contactForm": {
      "title": "Contact form",
      "btn": "Submit",
      "subtitle": "You can reach us through the following contact form. Please enter as many details as possible to your question, request or problem. Our employees will respond as soon as possible.",
      "tips": "By submitting the form I agree with the processing of the personal data entered in the form by the Base Technology Limited for the purpose of answering the question.",
      "placehloder": {
        "contactName": "Contact name",
        "email": "E-mail",
        "tel": "Tel",
        "subject": "Subject",
        "texarea": "Question"
      }
    }
  },
  "product": {
    "details": {
      "emailSigning": "Email Signing",
      "emailEncryption": "Email Encryption",
      "documentSigning": "Document Signing",
      "warranty": "Warranty (USD)",
      "codeLabel": "Company name",
      "emailLabel": "Secure addresses",
      "documentLabel": "Signatures a year"
    },
    "recommendUse": [
      {
        "title": "Recommended certificate use",
        "summary": [
          "The cheapest trusted TLS certificate from Thawte certification authority. It is issued in minutes thanks to quick DV vetting. It is great for securing common websites, encryption of sensitive data and for getting trust."
        ]
      }
    ],
    "recommendProduct": [
      {
        "title": "A certificate best-seller from $productName",
        "summary": [
          "The cheapest trusted TLS certificate from Thawte certification authority. It is issued in minutes thanks to quick DV vetting. It is great for securing common websites, encryption of sensitive data and for getting trust."
        ]
      }
    ],
    "pricePreview": {
      "btn": "Learn More",
      "title": "$productName price list",
      "yearPrice": "-year Price",
      "caPrice": "CA sells it for",
      "codePriceSummary1": "Huge savings over market price",
      "codePriceSummary2": "Lower price per year than 1-year",
      "codePriceSummary3": "Lower price per year over 2-year",
      "left": {
        "priceSummary": "Huge savings over the CA price",
        "addSan": "Additional SANs",
        "oneDomainPrice": "+ 1x domain (FQDN): $250.00",
        "oneWildPrice": "+ 1x Wildcard: $1,600.00"
      },
      "right": {
        "title": "A multi-year order",
        "imgUrl": "/images/mutil-chart-en.svg"
      },
      "summary": [
        "$productName certificate supports both RSA and ECC keys.",
        "You can add other domains such as FQDN and Wildcard SANs to the certificate."
      ],
      "content": {
        "title": "Ordering for more years will save you a lot of money! You can order TLS certificates for up to 6 years. You will receive the renewed follow-up certificate every year.",
        "summary": [
          "Save time. One order and payment = less administration.",
          "The longer the validity of the order, the higher the savings due to the decreasing annual price.",
          "You will receive the following one-year certificate automatically and on time, you just exchange it on the server."
        ]
      }
    },
    "browerPreview": {
      "dv": {
        "title": "The certificate in the browser",
        "summary": [
          "All the TLS certificates we offer are 100% trusted and website visitors are not bothered by browser errors.In a web browser, secure communication is shown in the address bar using the lock icon and the letter \"s\" next to the http in the URL.You can check the certificate owner and issuer by clicking on the lock. Learn moe about the TLS certificate in the browser."
        ],
        "coverImgUrl": "/images/EN_geo_OV.png"
      },
      "ov": {
        "title": "The certificate in the browser",
        "summary": [
          "All the TLS certificates we offer are 100% trusted and website visitors are not bothered by browser errors.\\nIn a web browser, secure communication is shown in the address bar using the lock icon and the letter \"s\" next to the http in the URL.\\nYou can check the certificate owner and issuer by clicking on the lock. Learn moe about the TLS certificate in the browser."
        ],
        "coverImgUrl": "/images/EN_geo_OV.png"
      },
      "ev": {
        "title": "The certificate in the browser",
        "summary": [
          "All the TLS certificates we offer are 100% trusted and website visitors are not bothered by browser errors.\\nIn a web browser, secure communication using an EV certificate is shown in the address bar showing the green company name, the lock icon, and the letter \"s\" next to the http in the URL.\\nYou can check the certificate owner and issuer by clicking on the lock. Learn moe about the TLS certificate in the browser."
        ],
        "coverImgUrl": "/images/EN_geo_OV.png"
      }
    },
    "premium": {
      "title": "Premium features of $brand Certificate"
    }
  },
  "brand": {
    "tableTitle": "Overview of TLS Wildcard certificates in our offer"
  },
  "privacy": {
    "pageTitle": "Privacy Policy - Certbase",
    "title": "Privacy Policy",
    "subtitle": "Personal Data Processing and GDPR Regulation"
  },
  "refund": {
    "pageTitle": "Refund Policy - Certbase",
    "title": "Refund Policy",
    "subtitle": "30-day refund policy for all SSL & web security solutions"
  },
  "member": {
    "pageTitle": "Member Agreement - Certbase",
    "title": "Member Agreement",
    "subtitle": "Member's terms and conditions of the certbase.com"
  },
  "terms": {
    "pageTitle": "Terms and Conditions - Certbase",
    "title": "Terms and Conditions",
    "subtitle": "Terms and Conditions"
  },
  "explainCert": {
    "pageTitle": "What is an SSL Certificate",
    "banner": {
      "title": "What is an SSL Certificate?",
      "subtitle": "Digital certificates serve as the backbone of internet security.",
      "summary": [
        "Secure Sockets Layer (SSL) certificates, sometimes called digital certificates, are used to establish an encrypted connection between a browser or user's computer and a server or website. The SSL connection protects sensitive data, such as credit card information, exchanged during each visit, which is called a session, from being intercepted from non-authorized parties."
      ],
      "imgUrl": "/images/explain-cert-banner.png"
    },
    "secureSocket": {
      "title": "Creating a Secure Connection",
      "subtitle": "SSL is the standard security technology for establishing an encrypted link — here’s how it works.",
      "summary": [
        "Invisible to the end-user, a process called the “SSL handshake” creates a secure connection between a web server and a browser. Three keys are used to create a symmetric session key, which is then used to encrypt all in-transit data."
      ],
      "imgUrl": "/images/explain-cert-banner.png",
      "list": [
        "Server sends a copy of its asymmetric public key to browser.",
        "Browser creates a symmetric session key and encrypts it with the server’s asymmetric public key then sends it to the server.",
        "Server decrypts the encrypted session key using its asymmetric private key to get the symmetric session key.",
        "Server and browser now encrypt and decrypt all transmitted data with the symmetric session key. This allows for a secure channel because only the browser and the server know the symmetric session key, and the session key is only used for that specific session. If the browser was to connect to the same server the next day, a new session key would be created."
      ]
    },
    "whyUse": {
      "title": "Why You Should Use a Digital Certificate",
      "subtitle": "Establish trust and online security for your website visitors and business.",
      "list": [
        "Encrypt sensitive data",
        "Activate HTTPS and the lock icon",
        "Comply with PCI standards",
        "Prove legitimacy",
        "Strengthen brand identity",
        "Increase SEO rank"
      ]
    },
    "contrast": {
      "title": "A Comparison of DV, OV and EV Certificates"
    },
    "effect": {
      "title": "Touch the EV SSL/TLS Certificates",
      "subtitle": "Paste your company name, country code and URL in the fields on the right – and click the padlock symbol in the address line to see your Company name and Country code."
    },
    "advantage": {
      "title": "Why do you need SSL/TLS Certificates?",
      "data": [
        {
          "title": "Stand out from your competition",
          "icon": "/icon/advantage__product_message.svg",
          "summary": "Not having SSL can be that one thing that keeps your users from buying your product. Having an excellent service or item isn’t enough! An SSL certificate can ensure that your users feel safe while making transactions or browsing your website."
        },
        {
          "title": "Build website trust the right way",
          "icon": "/icon/advantage__ conversion.svg",
          "summary": "Actions speak louder than words. Have a spotless history by protecting your business. Real protection for a low price. Where scandals and data leaks plague the internet, you can stay ahead by securing yourself and your users."
        },
        {
          "title": "Protect yourself from Cyber-crime",
          "icon": "/icon/advantage__brower.svg",
          "summary": "SSL/TLS Certificates can be the difference between success and loss. We offer you the best line of defence against “man in the middle attacks” that leave your data vulnerable to hijacking, theft and forgery."
        },
        {
          "title": "Make everyday your Payday",
          "icon": "/icon/advantage__4.svg",
          "summary": "HTTPS will increase your SEO ranking, which means you get higher traffic. Let your customers feel safe when they buy from your online store, make sure your revenue is constant and steadily growing. Make the right choice with SSL."
        },
        {
          "title": "Level up your SEO",
          "icon": "/icon/advantage__5.svg",
          "summary": "Security is a significant factor in your SEO rank. All the work you do to get your website to the top of the search results may be for nothing if your site doesn't have an SSL certificate. Take your ranking to the next level!"
        },
        {
          "title": "Build your Brand Smart",
          "icon": "/icon/advantage__6.svg",
          "summary": "Many qualities make up a strong and trustworthy brand image. Some of the most essential points are trust and security. Every single famous enterprise invests into protection and so can you with our SSL service."
        }
      ]
    },
    "preview": {
      "title": "Touch the EV SSL/TLS Certificates",
      "subtitle": "Paste your company name, country code and URL in the fields on the right – and click the padlock symbol in the address line to see your Company name and Country code.",
      "placeholder": {
        "company": "Company name",
        "country": "Country Code",
        "website": "Your Web address"
      },
      "body": {
        "title": "Enter the information and see your brand on the web",
        "tips": "Then click the green padlock symbol to see how your certificate information will look."
      },
      "popover": {
        "title": "Connection is secure",
        "tips": "Your information (for example, passwords or credit card numbers) is private when it is sent to this site.",
        "statusTitle": "Certificate (Valid)",
        "status": "Issued to: "
      }
    }
  },
  "payment": {
    "pageTitle": "Bank details and payment methods",
    "title": "Bank details and payment methods",
    "subtitle": "We offer payment by bank transfer to our checking account or by card, for faster on-line payment.",
    "revolut": {
      "title": "Revolut",
      "summary": [
        "This account supports incoming payments via SWIFT only. Transfers may take 1 - 3 business days to appear on our Revolut account."
      ],
      "list": [
        {
          "label": "SWIFT CODE:",
          "value": "HSBCHHHHH"
        },
        {
          "label": "Account Number:",
          "value": "128906670410802"
        },
        {
          "label": "Beneficiary:",
          "value": "Base Technology Limited"
        },
        {
          "label": "Adress:",
          "value": "Suite 1503, 15/F Carnival Comm BLDG 18 Java RD North Point, Hong Kong, China"
        }
      ]
    },
    "methods": [
      {
        "layout": "end",
        "title": "Payment via PayPal",
        "imgUrl": "/images/paypal.svg",
        "summary": [
          "Thanks to the PayPal payment system, you can pay online easily, quickly and above all, safely. The TLS certificate will be issued immediately."
        ]
      }
    ]
  },
  "references": {
    "pageTitle": "Our customers",
    "title": "Our customers",
    "customers": [
      "MANN+HUMMEL",
      "Dongzhong Group",
      "Sigma",
      "Neusoft Group",
      "Han's Laser",
      "Johnson & Johnson",
      "Liebherr"
    ],
    "summary": [
      "Base Technology Limited has been providing TLS certificates to major Asian companies, banks, public administration, and e-shops for many years. We provide our customers with a professional background for solving www project security problems and we provide highly quality support in several languages.When solving customer questions, we do not distinguish the size of the client, we also provide full support to customers who want to secure even simple websites.",
      "We have provided many thousands of TLS certificates for our customers. Only some of them are listed."
    ],
    "group": [
      {
        "groupName": "Commercial companies",
        "options": [
          "China Unicom",
          "One One Five",
          "IQIYI",
          "Panda TV",
          "China Telecom",
          "Fujian Jinhua"
        ]
      },
      {
        "groupName": "Banking and financial companies, insurance companies",
        "options": [
          "Rural Commercial Bank",
          "Clear Exchange",
          "Back pocket Jinke",
          "Reagan Securities",
          "China Merchants Jinke",
          "Renhe Life"
        ]
      }
    ]
  }
}

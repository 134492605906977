<!--
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-04-26 09:21:37
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-04-28 14:19:02
-->
<template>
  <div class="navbar" :class="`navbar--${$store.state.navbarClassStyle}`">
    <!-- <ComNavbar :mode="$store.state.navbarClassStyle !== 'default' ? 'primary' : 'default'" class="pc"/> -->
    <ComNavbar mode="default" class="pc"/>
    <MobNavbar class="mb"/>
  </div>
</template>

<script>
import ComNavbar from './ComNavbar.vue'
import MobNavbar from './MobNavbar.vue'

export default {
  name: 'Navbar',
  components: {
    ComNavbar,
    MobNavbar
  }
}
</script>
<style lang="less" scoped>
.navbar {
  height: 60px;
  padding: 0;
  overflow: hidden;
}
.navbar .mb {
  display: none;
}
@media screen and (max-width: 768px){
  .navbar .mb {
    display: block;
  }
  .navbar .pc {
    display: none;
  }
}
</style>

/**
 * 证书分析 中文版
 * 页面路由 https://certbase.com/certhub/analyse
 */

 // Banner
 const banner = {
  title: '证书分析工具',
  summary: '通过证书分析工具可以了解网络中的每个弱证书或过期证书。获取证书详细信息，可以快速检测端点网站安全配置，识别网站加密协议、套件以及关键漏洞',
  link: 'https://app.certbase.com/sign-up',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_analyse.svg'
}

// 证书分析说明
const manage = {
  title: '证书分析使您的工作流程自动化',
  options: [
    {
      title: '做出以数据为依据的决策',
      summary: '通过多个交互式图表评估整个景观，并在一个仪表板上查看全部信息。'
    },
    {
      title: '跟踪到期日期',
      summary: '通过动态窗口小部件监视即将发生的到期，并在证书即将到期时获得通知。'
    },
    {
      title: '优先任务',
      summary: '获取系统运行状况报告和有关关键系统事件的即时警报，以定义需要特别注意的证书。'
    }
  ]
}

// 特性
const features = {
  title: '证书分析的功能',
  options: [
    [
      // 若是图片填充填写图片URL 若不是保留空值
      { title: '系统健康状况概述', content: ['查看库存中证书的最重要统计信息：证书总数、每周续约状态、密钥较弱的证书、SHA-1证书等'], img: '' }, // 左
      { title: '上一次扫描结果', content: ['查看上次运行期间检测到的扫描日期，状态和新证书。'], img: '' } // 右
    ],
    [
      { title: '资料汇整', content: ['交互式饼图以可视方式呈现证书数据，分组方式为：签名哈希算法、证书签发类型、私钥长度、证书颁发机构'], img: '' },
      { title: '', content: [], img: '' }
    ],
    [
      { title: '风险警告和过期提醒', content: ['在检测到潜在安全威胁时立即获得警报。接收有关需要在1、7或30天内续订的证书的通知。'], img: '' },
      { title: '团队协作与自动报告', content: ['创建，查看和计划有关整体系统运行状况或特定证书组的报告。确定报告发送到团队电子邮件的频率。'], img: '' }
    ]
  ]
}

// 步骤
const step = {
  title: '3步使用证书分析',
  link: 'https://app.certbase.com/sign-up', // 按钮点击前往地址
  linkText: '立即体验',
  options: [
    {
      img: '',
      summary: '分析显示在仪表板小部件和图表中的证书数据。'
    },
    {
      img: '',
      summary: '安排定期报告以与系统状态保持同步。'
    },
    {
      img: '',
      summary: '设置通知和警报以跟踪可能导致中断的证书。'
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}

<template>
  <div class="change-language" :class="type ? `change-language--${type}` : ''">
    <el-popover
      v-model="popover"
      trigger="hover"
      popper-class="local-popover">
      <!-- <img slot="reference" v-if="type !== 'primary'" class="icon-global" src="/icon/local-white.svg" alt="" />
      <img slot="reference" v-else class="icon-global" src="/icon/local.svg" alt="" /> -->
      <span slot="reference" class="flex locale-content">
        {{ formatLocale(language) }}
        <i class="ri-arrow-down-s-line"></i>
      </span>
      <ul class="change-language-list">
        <li
          v-for="item in locals"
          :key="item.value"
          class="change-language-item"
          @click="handleChangeLanguage(item)">
            {{ item.label }}
          </li>
      </ul>
    </el-popover>
  </div>
</template>

<script>

const locals = [
  { label: '简体中文', value: 'zh' },
  { label: 'English', value: 'en' }
]

const formatLocale = (code) => {
  return locals.find(item => code === item.value).label
}

export default {
  name: 'ChangeLocal',
  props: {
    type: String
  },
  data () {
    return {
      locals,
      popover: false,
      language: this.$store.state.locale || 'zh'
    }
  },
  created () {
    const lang = this.$route.query.lang
    if (lang) {
      this.language = lang
      this.$store.commit('changeLocale', this.$i18n.locale)
      this.$i18n.locale = lang
      this.$cookie.set('locale', this.language)
    }
  },
  methods: {
    formatLocale,
    handleChangeLanguage ({ value: language }) {
      if (language === this.$cookie.get('locale')) {
        this.popover = false
        return
      }
      this.$i18n.locale = language
      this.$store.commit('changeLocale', language)
      this.$cookie.set('locale', language)
      window.location.reload()
      this.popover = false
    }
  }
}
</script>
<style lang="less">
.local-popover.el-popover {
  min-width: unset;
  display: inline-block;
  padding: 0;
}
</style>
<style lang="less" scoped>
.change-language {
  // width: 25px;
  position: relative;
}
.locale-content {
  white-space: nowrap;
  cursor: pointer;
}
.icon-global {
  width: 24px;
  height: 24px;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  cursor: pointer;
}
.change-language-item {
  white-space: nowrap;
  padding: 6px 12px;
  cursor: pointer;
  &:hover {
    background-color: fade(@theme-color, 10);
  }
}
.change-language-item:first-child {
  padding-top: 12px;
}
.change-language-item:last-child {
  padding-bottom: 12px;
}
</style>

export default {
  report: {
    title: '违法和不良信息举报',
    subtitle: '禁止将汇智云安产品用于含诈骗、赌博、色情、木马、病毒等违法违规业务。Certbase 将对违规使用者封停其账号。',
    type: {
      title: '举报类型',
      options: [
        { label: '诈骗类', value: 'faker' },
        { label: '色情类', value: '2' },
        { label: '政治类', value: '3' },
        { label: '暴恐类', value: '4' },
        { label: '低俗类', value: '5' },
        { label: '赌博类', value: '6' },
        { label: '侵权类', value: '7' },
        { label: '谣言类', value: '8' },
        { label: '其他类', value: '9' },
      ]
    },
    urls: {
      label: '有害信息的网址',
      place: '请输入举报的URL地址，如有多个请换行填写'
    },
    summary: {
      label: '有害信息描述',
      place: '请输入举报的内容描述，内容限制500字符'
    },
    img: {
      label: '上传截图',
      notify: {
        format: '上传图片只能是 JPG 或 PNG 格式!',
        size: '上传图片大小不能超过 2MB!'
      }
    },
    progress: {
      title: '举报信息处置流程',
      summary: '即日起，在汇智云安官网显著位置设置“违规信息举报”专区入口，充分发挥社会公众的参与监督作用，本网举报信息的处置流程为：',
      list: [
        '我们根据举报信息，对举报内容进行细化分类、甄别核实，上报汇智云安信息安全小组。',
        'Certbase 信息安全小组根据举报信息，对举报内容进行核实。',
        '对于属实的举报信息，信息安全小组将立即上报相关领导，第一时间做出处置。',
        '涉及危害国家安全、扰乱社会秩序、侵犯国家社会集体和公民的合法权益等内容的重要举报信息，本网将及时上报相关主管部门。',
        '本网将严格保护举报人的个人隐私，不向第三方机构或个人透露举报人的任何信息。',
        '对于举报受理及处置过程中所形成的资料，我们会及时整理归档并做好保密工作。'
      ]
    },
    submit: '提交信息',
    reset: '重置',
    successSubmit: '已成功提交信息！'
  }
}

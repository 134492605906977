<template>
  <div class="nvabr-expand" v-if="showMask">
    <div class="navbar-mask" @click.self="$emit('close')">
      <div class="nvabr-expand-body">
        <!-- SSL -->
        <div v-if="mode === 'ssl'" class="container flex">
          <div class="nvabr-expand-left flex flex-start">
            <div class="nvabr-expand-col">
              <div class="col-title">{{ $t('nnavbar').brand.title }}</div>
              <div class="flex">
                <ul
                  v-for="(brandGroup, groupIndex) in brands"
                  :key="groupIndex"
                  class="brand-group"
                >
                  <li
                    v-for="brand in brandGroup"
                    :key="brand.brand"
                    class="ssl-item"
                  >
                    <router-link :to="`/brand${brand.brandPath}`">{{ brand.brand }}</router-link>
                  </li>
                </ul>
              </div>
          </div>
            <!-- 证书类型 -->
            <div class="nvabr-expand-col">
              <div class="col-title">{{ $t('nnavbar').type.title }}</div>
              <ul>
                <li
                  v-for="(item) in $t('nnavbar').type.options"
                  :key="item.link"
                  class="ssl-item"
                >
                  <router-link :to="item.link">{{ item.label }}</router-link>
                </li>
              </ul>
            </div>
            <!-- 验证类型 -->
            <div class="nvabr-expand-col">
              <div class="col-title">{{ $t('nnavbar').validation.title }}</div>
              <ul>
                <li
                  v-for="(item) in $t('nnavbar').validation.options"
                  :key="item.link"
                  class="ssl-item"
                >
                  <router-link :to="item.link">{{ item.label }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="nvabr-expand-right">
            <img src="@/assets/navbar/product.svg" alt="">
          </div>
        </div>
        <!-- 数字签名 -->
        <div v-if="mode === 'cert'" class="container flex">
          <div class="nvabr-expand-left flex flex-start digital-signature">
            <router-link
              v-for="(item, index) in $t('nnavbar').sign"
              :key="`navbar-sign-${index}`"
              :to="item.link"
              class="nvabr-expand-col"
            >
              <div class="col-icon">
                <img :src="item.icon" alt="">
              </div>
              <div class="col-title">{{ item.title }}</div>
              <section class="col-description">{{ item.desc }}</section>
            </router-link>
          </div>
          <div class="nvabr-expand-right">
            <img src="@/assets/navbar/sign.svg" alt="">
          </div>
        </div>
        <!-- 证书管理 -->
        <div v-if="mode === 'certhub'" class="container flex">
          <div class="nvabr-expand-left flex flex-start">
            <div
              v-for="(item, index) in $t('nnavbar').tools"
              :key="`navbar-tools-${index}`"
              class="nvabr-expand-col"
            >
              <div class="col-title">{{ item.title }}</div>
              <section class="col-description">{{ item.desc }}</section>
              <div class="flex">
                <ul
                  v-for="(group, gIndex) in item.options"
                  :key="`navbar-tools-${index}-${gIndex}`"
                  class="brand-group"
                >
                  <li
                    v-for="(unit, unitIndex) in group"
                    :key="`navbar-tools-${index}-${gIndex}-${unitIndex}`"
                    class="ssl-item">
                    <router-link :to="unit.link">{{ unit.label }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="nvabr-expand-right">
            <img src="@/assets/navbar/tools.svg" alt="">
          </div>
        </div>
        <!-- 解决方案 compliance -->
        <div v-if="mode === 'compliance'" class="container flex">
          <div class="nvabr-expand-left flex flex-start compliance">
            <div
              v-for="(group, gIndex) in $t('nnavbar').compliance"
              :key="`compliance-group-${gIndex}`"
              class="nvabr-expand-col"
            >
              <div v-if="group.title" class="col-title">{{ group.title }}</div>
              <div v-else class="col-title">&nbsp;</div>
              <ul>
                <li
                  v-for="item in group.options"
                  :key="item.link"
                  class="ssl-item">
                  <router-link :to="item.link">{{ item.label }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="nvabr-expand-right">
            <img src="@/assets/navbar/auth.svg" alt="">
          </div>
        </div>
        <!-- 合作伙伴 -->
        <div v-if="mode === 'partner'" class="container flex">
          <div class="nvabr-expand-left flex flex-start partner">
            <router-link
              v-for="item in $t('nnavbar').partner"
              :key="item.link"
              :to="item.link"
              class="nvabr-expand-col"
            >
              <div class="col-icon">
                <img :src="item.icon" alt="">
              </div>
              <div class="col-title">{{ item.title }}</div>
              <section class="col-description">{{ item.desc }}</section>
            </router-link>
          </div>
          <div class="nvabr-expand-right">
            <img src="@/assets/navbar/partner.svg" alt="">
          </div>
        </div>
        <!-- 帮助教程 -->
        <div v-if="mode === 'help'" class="container flex">
          <div class="nvabr-expand-left flex flex-start help">
            <div class="nvabr-expand-col">
              <!-- <div class="col-title">帮助内容</div> -->
              <ul>
                <li class="ssl-item"><a href="/help/faq">常见问题</a></li>
                <li class="ssl-item"><a href="/help/vetting">审核与验证流程</a></li>
                <li class="ssl-item"><a href="/tools/ssl-config">TLS 证书安装流程</a></li>
                <li class="ssl-item"><a href="/help/explore-certbase">Certbase 平台使用教程</a></li>
              </ul>
            </div>
            <div class="nvabr-expand-col">
              <!-- <div class="col-title">关键一招</div> -->
              <ul>
                <li class="ssl-item"><a href="/help/glossary">TLS 常用术语</a></li>
                <li class="ssl-item"><a href="/help/faq">通用答案与故障排除</a></li>
                <li class="ssl-item"><a href="/help/automatization">ACME 与 API自动化</a></li>
              </ul>
            </div>
          </div>
          <div class="nvabr-expand-right">
            <img src="@/assets/navbar/support.svg" alt="">
          </div>
        </div>
        <!-- 联系我们 -->
        <div v-if="mode === 'contact'" class="container flex flex-start contact">
          <div class="nvabr-expand-col">
            <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.contactUs }}</h4>
            </div>
            <!-- <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.serviceTime }}</h4>
              <p>
                <a href="tel:400-128-0988" class="link-style">{{ $t('nnavbar').contact.serviceTel[0] }}</a>
                <a href="tel:177-0811-9188" class="link-style">{{ $t('nnavbar').contact.serviceTel[1] }}</a>
              </p>
            </div> -->
            <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.tel }}</h4>
              <!-- <p>
                <a href="tel:400-128-0988" class="link-style">{{ $t('nnavbar').contact.serviceTel[0] }}</a>
              </p>
              <p>
                <a href="tel:177-0811-9188" class="link-style">{{ $t('nnavbar').contact.serviceTel[1] }}</a>
              </p> -->
            </div>
            <div class="contact-item tel-number">
              <p>
                <a href="tel:400-128-0988" class="link-style">{{ $t('nnavbar').contact.serviceTel[0] }}</a>
              </p>
              <p>
                <a href="tel:177-0811-9188" class="link-style">{{ $t('nnavbar').contact.serviceTel[1] }}</a>
              </p>
            </div>
            <!-- <div class="contact-item">
              <h4 class="col-title">地址</h4>
              <p>成都市天府新区华府大道1号蓝润置地T3</p>
            </div> -->
            <!-- <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.aboutUs }}</h4>
              <a href="/about-us" class="link-style">{{ $t('nnavbar').contact.slogan }}</a>
            </div> -->
            <!-- <div class="contact-item">
              <h4 class="col-title">邮件</h4>
              <a href="mailto:support@sslcertificateshop.com" class="link-style">support@sslcertificateshop.com</a>
            </div> -->
          </div>
          <div class="nvabr-expand-col">
            <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.callback }}</h4>
            </div>
            <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.notWait }}</h4>
            </div>
            <div class="contact-item">
              <!-- <CButton border type="primary">{{ $t('nnavbar').contact.callback }}</CButton> -->
              <a href="mailto:support@sslcertificateshop.com" class="link-style">support@sslcertificateshop.com</a>
            </div>
          </div>
          <div class="nvabr-expand-col">
            <div class="contact-item">
              <h4 class="col-title">{{ $t('nnavbar').contact.chatNow }}</h4>
            </div>
            <div class="contact-item">
              <CButton border type="primary" @click="openChat()" :click="true">
                {{ $t('nnavbar').contact.contactNow }}
              </CButton>
            </div>
            <div class="contact-item">{{ $t('nnavbar').contact.chatToTeam }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { formatNavbar } from '@/api/init'

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'default' // ssl -> ssl证书 || default -> 数字证书
    }
  },
  setup(props, { root }) {
    const showMask = ref(true)
    
    const brands = computed(() => {
      const products = root.$store.state.products
      const brandList = formatNavbar(products)
      const len = brandList.length
      const data = [brandList.slice(0, len / 2), brandList.slice(len / 2)]
      return data
    })

    const closeMask = () => {
      showMask.value = false
    }

    const openChat = () => {
      window._AIHECONG && window._AIHECONG('showChat')
      window.Intercom && window.Intercom('showMessages')
    }

    return {
      brands,
      showMask,
      closeMask,
      openChat
    }
  }
})
</script>

<style lang="less" scoped>
.nvabr-expand {
  color: initial;
  border-top: @border;
  a {
    position: relative;
    color: @text-color-l;
    text-decoration: none;
  }
}
.navbar-mask {
  background-color: fade(#000, 50);
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
}
.nvabr-expand-body {
  min-height: 100px;
  padding: 48px 0;
  background-color: #FFF;
  transition: .3s ease;
}
.nvabr-expand-left {
  border-right: @border;
  min-width: 70%;
}
.nvabr-expand-right {
  padding-left: 68px;
  img {
    display: block;
    width: 200px;
  }
}

.contact {
  justify-content: space-between;
  .nvabr-expand-col {
    min-height: 190px;
    flex: 1 1 20em;
    margin-right: 0;
    padding: 24px 0;
    &:not(:first-child) {
      padding-left: 24px;
    }
  }
  .nvabr-expand-col:nth-child(2) {
    border-left: @border;
    border-right: @border;
  }
  // .nvabr-expand-col:first-child {
  //   justify-content: flex-start;
  // }
  .nvabr-expand-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tel-number p:last-child {
    margin-top: 12px;
  }
  .col-title ~ p {
    margin-top: 6px;
  }
  .contact-item ~ .contact-item {
    margin-top: 24px;
  }
}

.col-title {
  font-size: 16px;
  font-weight: 600;
}
.col-icon {
  width: 72px;
  height: 72px;
  // background-color: #CCC;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}
.col-description {
  color: @text-color-l;
  margin-top: 12px;
  max-width: 150px;
}
.brand-group:first-child,
.nvabr-expand-col {
  margin-right: 68px;
}
.ssl-item {
  margin-top: 24px;
  color: @text-color-l;
  a {
    position: relative;
  }
  &:hover a::before {
    width: 100%;
  }
  a::before {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: .3s ease-out;
    background-color: @theme-color;
  }
}
.help,
.compliance {
  .col-title {
    margin-bottom: 24px;
  }
  padding: 24px 0;
  .ssl-item:first-child {
    margin-top: 0;
  }
}

.partner,
.digital-signature {
  padding: 24px 0;
  .nvabr-expand-col {
    padding: 24px;
    margin-right: 22px;
  }
  .nvabr-expand-col:hover {
    background-color: fade(@theme-color, 10);
  }
}
</style>

import home from './home_zh'
import chain from './chain_zh'
import discovery from './discover_zh'
import inventory from './inventory_zh'
import analyse from './analyse_zh'
import audit from './audit_zh'
import monitor from './monitor_zh'
import log from './log_zh'
// tools
import sslConfig from './tls_config_zh'
import CreateCsr from './create_csr_zh'
import ParseCsr from './parse_csr_zh'
import ParseCert from './parse_cert_zh'
import ConvertCert from './convert_cert_zh'
import MatchCert from './match_cert_zh'

export default {
  certhub: {
    navLeftTitle: '证书工具',
    navLeft: [
      [
        { label: '创建 CSR', path: '/tools/gen-csr' },
        { label: '解析 CSR', path: '/tools/parse-csr' },
        { label: '解析证书', path: '/tools/parse-cert' },
        { label: '公私钥匹配', path: '/tools/match' },
        { label: '证书格式转换', path: '/tools/convert-cert' },
      ],
      [
        { label: 'TLS 配置生成器', path: '/tools/ssl-config' }
      ]
    ],
    navRight: [
      { label: '证书管理', path: '/certhub' },
      { label: '发现证书', path: '/certhub/discovery' },
      { label: '证书库存管理', path: '/certhub/inventory' },
      { label: '证书分析', path: '/certhub/analyse' },
      { label: '证书审计', path: '/certhub/audit' },
      { label: '证书监控', path: '/certhub/monitor' },
      { label: '证书日志查询', path: '/certhub/log' }
    ],
    // 折叠导航右侧内容
    desc: {
      title: 'SSL/TLS 证书发现和管理平台。',
      linkText: '免费体验',
      link: 'https://app.certbase.com/sign-up', // 前往地址
      summary: '注册账户即可体验先进的云端证书管理平台，通过自动执行证书生命周期的每个步骤，可以轻松保护客户并保护您的品牌。'
    },
    log,
    home,
    chain,
    audit,
    analyse,
    monitor,
    discovery,
    inventory,
  },
  ...sslConfig,
  ...CreateCsr,
  ...ParseCsr,
  ...ParseCert,
  ...ConvertCert,
  ...MatchCert
}

<template>
  <a
    :href="href"
    class="btn"
    target="_blank"
    @click="handleClick"
    v-bind="$attrs"
    :class="{
      'btn--border': !isUndefiend(border),
      'btn--primary': type === 'primary',
      'btn--text': !isUndefiend(text)
    }">
    <slot>{{ $t('common').buyNow }}</slot>
  </a>
</template>

<script>
import { ref, SetupContext } from '@vue/composition-api'
const isUndefiend = (val) => val === undefined

// 参数组装
function genQuery (brand, validType, domainType, productType, year) {
  const query = { brand, validType, domainType, productType, year } // eslint-disable-line
  let link = ''
  for (const key in query) {
    if (query.hasOwnProperty(key)) { // eslint-disable-line
      if (!query[key]) {
        delete query[key]
      } else {
        link += `${key}=${query[key]}&`
      }
    }
  }
  return link.replace(/&$/, '')
}

export default {
  name: 'CButton',
  props: {
    border: String,
    type: String,
    text: String,
    click: Boolean, // Use custom click event
    link: String,
    year: {
      type: Number,
      default: 1
    },
    brand: String, // 品牌、全小写
    validType: { // 验证类型 dv | ov | ev
      type: String,
      default: 'dv'
    },
    domainType: { // 域名类型 one | wild | flex
      type: String,
      default: 'flex'
    },
    productType: { // 产品类型 ssl | doc | code | email
      type: String,
      default: 'ssl'
    }
  },
  setup (props, ctx) { // eslint-disable-line
    const queryLink = genQuery(props.brand, props.validType, props.domainType, props.productType, props.year + '')
    const buyLink = `${process.env.VUE_APP_TARGET}?${queryLink}`
    const href = ref(props.link || buyLink)
    const handleClick = (e) => {
      if (props.click) {
        e?.preventDefault()
        ctx.emit('click')
        return
      }
      href.value = buyLink
    }
    // handleClick()
    return {
      href,
      isUndefiend,
      handleClick
    }
  }
}
</script>
<style lang="less" scoped>
.btn {
  color: #606266;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid transparent;
  padding: 6px 12px;
  border-radius: 4px;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
  font-size: 15px;
  transition: .4s ease;
}
.btn--primary {
  color: #fff;
  background-color: @theme-color;
}
.btn--text {
  color: #fff;
  background-color: transparent;
}
.btn--text.btn--primary {
  color: @theme-color;
}
.btn--border {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  &.btn--primary {
    border-color: #c3d8fe;
    color: @theme-color;
  }
  &.btn--primary:hover {
    color: @theme-color;
    background-color: #ebf2ff;
    border-color: @theme-color;
  }
}
</style>

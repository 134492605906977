<template>
  <div class="navbar">
    <div class="nav-container">
      <div class="flex flex-between container">
        <div
          @click="handleMenuBtnClick()"
          class="navbar-menu-controller"
          :class="{ 'is-expand': expand }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="navbar-logo">
          <a @click.prevent="backHome()">
            <Logo />
          </a>
        </div>
        <div class="flex">
          <ChangeLocal type="primary"/>
          <a class="login-btn--user" href="https://app.certbase.com/sign-in">
            <img src="/icon/login-user.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
    <NavbarExpand
      @close="handleMenuBtnClick()"
      v-show="expand"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// import NavbarExpand from './MobNavbarExpand.vue'
import NavbarExpand from './MobNavbarExpandNew.vue'
import ChangeLocal from '../ChangeLocal.vue'

export default {
  name: 'Navbar',
  components: {
    NavbarExpand,
    ChangeLocal
  },
  setup () {
    const expand = ref(false)
    const handleMenuBtnClick = () => {
      expand.value = !expand.value
      if (expand.value) {
        document.body.setAttribute('style', 'height: 100vh;overflow: hidden;')
      } else {
        document.body.removeAttribute('style')
      }
    }

    const backHome = () => {
      window.location.href = '/'
      expand.value = false
    }
    return {
      expand,
      backHome,
      handleMenuBtnClick
    }
  }
}
</script>
<style lang="less" scoped>
.navbar {
  // height: 60px;
  position: relative;
}
.container {
  height: 60px;
}
.nav-container {
  // width: 100%;
  width: 100vw;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 60px;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  overflow: hidden;
}
// menu btn
.navbar-menu-controller {
  width: 24px;
  cursor: pointer;
  overflow: hidden;
  @origin: 23.2px;
  // margin-left: 12px;
  span {
    height: 2px;
    width: 100%;
    display: block;
    background-color: @text-color-l;
    margin-bottom: 6px;
    transition: .4s ease-out;
    transform-origin: @origin;
    border-radius: 5px;
  }
  span:last-child {
    margin-bottom: 0;
  }
  // expand status
  &.is-expand {
    span:nth-child(1) {
      transform: rotate(-45deg);
    }
    span:nth-child(3) {
      transform: rotate(45deg);
    }
    span:nth-child(2) {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
}
.login-btn--user {
  margin-left: 12px;
  transform: translateY(1px);
  [class*="ri-user"] {
    font-size: 22px;
  }
  img {
    width: 25px;
    height: 25px;
  }
}
</style>

<template>
  <div class="help-media-right">
    <h1 class="help-media__title">{{ $t('help').navbar.right.title }}</h1>
    <section class="help-media__summary">{{ $t('help').navbar.right.summary }}</section>
    <div class="flex">
      <CButton :href="$t('help').navbar.right.btn[0].link" type="primary">{{ $t('help').navbar.right.btn[0].linkText }}</CButton>
      <CButton :href="$t('help').navbar.right.btn[1].link" type="primary">{{ $t('help').navbar.right.btn[1].linkText }}</CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpMediaRight'
}
</script>

<style lang="less" scoped>
.help-media__summary {
  margin: 24px 0;
}
@media screen and (max-width: 768px) {
  .help-media__title {
    display: none;
  }
  .help-media-right {
    border-top: @border;
    margin-top: 24px;
  }
}
</style>

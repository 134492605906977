<template>
  <!-- PEM 扩展组件 -->
  <section class="csr-pre">
    <el-upload
      v-if="showUpload"
      drag
      action=""
      ref="textarea-upload"
      class="upload-input"
      :show-file-list="true"
      :before-upload="handleSelectFile"
      :file-list="files">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        <p v-if="only !== 'upload'">{{ $t('keyTextarea').textarea[0] }}</p>
        <span v-if="only !== 'upload'">{{ $t('keyTextarea').textarea[1] }}</span><span>{{ $t('keyTextarea').textarea[2] }}<em>{{ $t('keyTextarea').textarea[3] }}</em></span>
      </div>
      <div v-if="filename" class="el-upload_filename">{{ $t('keyTextarea').textarea[4] }}}{{ filename }}</div>
      <div class="el-upload__tip" slot="tip" v-if="tips">{{ tips }}</div>
    </el-upload>
    <label>
      <textarea
        @focus="handleFocusChange"
        @blur="handleBlurChange"
        @change="(e) => $emit('change', e)"
        v-model="content"
        :placeholder="placeholder"
        :style="{ minHeight: height }"
        :readonly="readonly"
        :disabled="disabled || only === 'upload'"
        :autofocus="autofocus"
        class="csr-container"
        :class="{ 'is-focus': isFocus }">
      </textarea>
    </label>
  </section>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'KeyTextarea',
  props: {
    value: String,
    parse: Boolean, // 是否需要解析csr
    parseValue: Object,
    tips: String, // 上传提示
    height: {
      type: String,
      default: '350px'
    },
    placeholder: {
      type: String,
      default: '在此处粘贴您 PEM 的格式 CSR'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: Boolean,
    only: { // upload | input
      type: String,
      default: '' // upload
    }
  },
  setup(props, ctx) {
    const content = ref(props.value)
    const showUpload = ref(true) // 显示上传组件
    const isFocus = ref(false) // 当内容不为空或获取焦点的时候
    const filename = ref('')
    const files = ref([])

    const handleSelectFile = (file) => {
      ctx.emit('select-files', file)
      filename.value = file.name
      if (props.only === 'upload') return false
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = function(e) {
        content.value = e.target.result
      }
      return false
    }

    // 监听文本域焦点
    const handleFocusChange = (e) => {
      if (props.only === 'upload') return
      ctx.emit('focus', e)
      isFocus.value = true
      showUpload.value = false
    }

    // 监听文本域失焦
    const handleBlurChange = (e) => {
      ctx.emit('blur', e)
      if (!content.value || !props.value) {
        showUpload.value = true
        isFocus.value = false
      }
    }

    watch(() => props.value, (val) => {
      content.value = val
      if (val) {
        showUpload.value = false
        isFocus.value = true
      }
      if (!val) {
        showUpload.value = true
        filename.value = ''
      }
    })
    watch(() => content.value, (val) => {
      if (val) {
        showUpload.value = false
        isFocus.value = true
      } else {
        showUpload.value = true
        filename.value = ''
      }
      ctx.emit('input', val)
    }, { lazy: true })
    return {
      files,
      isFocus,
      content,
      filename,
      showUpload,
      handleSelectFile,
      handleBlurChange,
      handleFocusChange
    }
  }
}
</script>

<style lang="less" scoped>
  @distance: 12px;
  .csr-pre {
    width: 100%;
    max-width: 620px;
    position: relative;
    label {
      display: block;
    }
  }
  .csr-container {
    outline: none;
    width: calc(100% - 24px);
    max-width: 620px;
    display: block;
    padding: @distance;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
    border: @border;
    transition: .2s ease;
    border-radius: 6px;
    &.is-focus {
      border-color: @theme-color;
    }
  }
  // 上传组件
  .upload-input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .el-upload_filename {
    color: @theme-color;
  }
  @media screen and (max-width: 768px){
    .csr-pre {
      & /deep/ .el-upload-dragger {
        width: 258px;
      }
    }
  }
</style>

<template>
  <div class="mob-navbar-expand flex flex-start">
    <ul class="navbar-menu">
      <li
        v-for="(item, index) in $t('navbar').options"
        :key="`mo-navbar-expand-${index}`"
        :class="{ active: item.path === selected.path }"
        class="flex"
        @click="handleSelected(item)"
      >
        {{ item.text }}
      </li>
    </ul>
    <div class="navbar-viewer">
      <!-- SSL -->
      <div v-if="selected.expand === 'ssl' && brands.length">
        <div class="nvabr-expand-left">
          <div class="nvabr-expand-col">
            <div class="col-title">{{ $t('nnavbar').brand.title }}</div>
            <div>
              <ul
                v-for="(brandGroup, groupIndex) in brands"
                :key="groupIndex"
                class="brand-group"
              >
                <li
                  v-for="brand in brandGroup"
                  :key="brand.brand"
                  class="ssl-item"
                >
                  <a :href="`/brand${brand.brandPath}`">{{ brand.brand }}</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- 证书类型 -->
          <div class="nvabr-expand-col">
            <div class="col-title">{{ $t('nnavbar').type.title }}</div>
            <ul>
              <li
                v-for="(item) in $t('nnavbar').type.options"
                :key="item.link"
                class="ssl-item"
              >
                <a :href="item.link">{{ item.label }}</a>
              </li>
            </ul>
          </div>
          <!-- 验证类型 -->
          <div class="nvabr-expand-col">
            <div class="col-title">{{ $t('nnavbar').validation.title }}</div>
            <ul>
              <li
                v-for="(item) in $t('nnavbar').validation.options"
                :key="item.link"
                class="ssl-item"
              >
                <a :href="item.link">{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 数字签名 -->
      <div v-if="selected.expand === 'cert'">
        <div class="nvabr-expand-left digital-signature">
          <a
            v-for="(item, index) in $t('nnavbar').sign"
            :key="`navbar-sign-${index}`"
            :href="item.link"
            class="nvabr-expand-col"
          >
            <div class="col-icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="col-title">{{ item.title }}</div>
            <section class="col-description">{{ item.desc }}</section>
          </a>
        </div>
      </div>
      <!-- 证书管理 -->
      <div v-if="selected.expand === 'certhub'" class="tools">
        <div class="nvabr-expand-left">
          <div
            v-for="(item, index) in $t('nnavbar').tools"
            :key="`navbar-tools-${index}`"
            class="nvabr-expand-col"
          >
            <div class="col-title">{{ item.title }}</div>
            <section class="col-description">{{ item.desc }}</section>
            <div class="flex">
              <ul
                v-for="(group, gIndex) in item.options"
                :key="`navbar-tools-${index}-${gIndex}`"
                class="brand-group"
              >
                <li
                  v-for="(unit, unitIndex) in group"
                  :key="`navbar-tools-${index}-${gIndex}-${unitIndex}`"
                  class="ssl-item">
                  <a :href="unit.link">{{ unit.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案 compliance -->
      <div v-if="selected.expand === 'compliance'">
        <div class="nvabr-expand-left compliance">
          <div
            v-for="(group, gIndex) in $t('nnavbar').compliance"
            :key="`compliance-group-${gIndex}`"
            class="nvabr-expand-col"
          >
            <div v-if="group.title" class="col-title">{{ group.title }}</div>
            <div v-else class="col-title">&nbsp;</div>
            <ul>
              <li
                v-for="item in group.options"
                :key="item.link"
                class="ssl-item">
                <a :href="item.link">{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div v-if="selected.expand === 'partner'">
        <div class="nvabr-expand-left partner">
          <a
            v-for="item in $t('nnavbar').partner"
            :key="item.link"
            :href="item.link"
            class="nvabr-expand-col"
          >
            <div class="col-icon"></div>
            <div class="col-title">{{ item.title }}</div>
            <section class="col-description">{{ item.desc }}</section>
          </a>
        </div>
      </div>
      <!-- 帮助教程 -->
      <div v-if="selected.expand === 'help'" class="container flex">
        <div class="nvabr-expand-left flex flex-start help">
          <div class="nvabr-expand-col">
            <!-- <div class="col-title">帮助内容</div> -->
            <ul>
              <li class="ssl-item"><a href="/help/faq">常见问题</a></li>
              <li class="ssl-item"><a href="/help/vetting">审核与验证流程</a></li>
              <li class="ssl-item"><a href="/tools/ssl-config">TLS 证书安装流程</a></li>
              <li class="ssl-item"><a href="/help/explore-certbase">Certbase 平台使用教程</a></li>
            </ul>
          </div>
          <div class="nvabr-expand-col">
            <!-- <div class="col-title">关键一招</div> -->
            <ul>
              <li class="ssl-item"><a href="/help/glossary">TLS 常用术语</a></li>
              <li class="ssl-item"><a href="/help/faq">通用答案与故障排除</a></li>
              <li class="ssl-item"><a href="/help/automatization">ACME 与 API自动化</a></li>
            </ul>
          </div>
        </div>
        <div class="nvabr-expand-right">
          <img src="@/assets/navbar/support.svg" alt="">
        </div>
      </div>
      <!-- 联系我们 -->
      <div v-if="selected.expand === 'contact'" class="container flex contact">
        <div class="nvabr-expand-col">
          <div class="contact-item">
            <h4 class="col-title">{{ $t('nnavbar').contact.contactUs }}</h4>
          </div>
          <div class="contact-item">
            <h4 class="col-title">{{ $t('nnavbar').contact.serviceTime }}</h4>
            <p>
              <a href="tel:400-128-0988" class="link-style">{{ $t('nnavbar').contact.serviceTel[0] }}</a>
              <a href="tel:177-0811-9188" class="link-style">{{ $t('nnavbar').contact.serviceTel[1] }}</a>
            </p>
          </div>
          <div class="contact-item">
            <h4 class="col-title">{{ $t('nnavbar').contact.aboutUs }}</h4>
            <a href="/about-us" class="link-style">{{ $t('nnavbar').contact.slogan }}</a>
          </div>
        </div>
        <div class="nvabr-expand-col">
          <div class="contact-item">
            <h4 class="col-title">{{ $t('nnavbar').contact.callback }}</h4>
          </div>
          <div class="contact-item">
            <h4 class="col-title">{{ $t('nnavbar').contact.notWait }}</h4>
          </div>
          <div class="contact-item">
            <CButton border type="primary">{{ $t('nnavbar').contact.callback }}</CButton>
          </div>
        </div>
        <div class="nvabr-expand-col">
          <div class="contact-item">
            <h4 class="col-title">{{ $t('nnavbar').contact.chatNow }}</h4>
          </div>
          <div class="contact-item">
            <CButton border type="primary" @click="openChat()" :click="true">立即聊天</CButton>
          </div>
          <div class="contact-item">{{ $t('nnavbar').contact.chatToTeam }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { formatNavbar } from '@/api/init'
import HelpMediaRight from './HelpMediaRight.vue'
import HelpMediaLeft from './HelpMediaLeft.vue'
import ComplianceLeft from './ComplianceLeft.vue'

export default {
  name: 'MobNavbarExpand',
  components: {
    HelpMediaRight,
    HelpMediaLeft,
    ComplianceLeft
  },
  setup (props, ctx) {
    const { root } = ctx
    // const { $router } = root
    const selected = ref({ path: '/ssl', expand: 'ssl' })
    const brandsData = computed(() => {
      let products = root.$store.state.products
      if (selected.value.path === '/ssl') {
        products = products.filter((item) => /ssl/.test(item.sslType + ''))
      }
      products = formatNavbar(products).filter(item => item.options.length > 0)
      return products
    })

    // 品牌列表
    const brands = computed(() => {
      const products = root.$store.state.products
      const brandList = formatNavbar(products)
      const len = brandList.length
      const data = [brandList.slice(0, len / 2), brandList.slice(len / 2)]
      return data
    })

    const getBrands = (products) => {
      const brandList = formatNavbar(products)
      const len = brandList.length
      const data = [brandList.slice(0, len / 2), brandList.slice(len / 2)]
      return data
    }

    const navCertData = computed(() => root.$store.state.navbarCertData)

    // Handle selected action
    const handleSelected = (selectedItem) => {
      console.log(selectedItem)
      selected.value = selectedItem
      // if (selectedItem.path !== '/ssl' && selectedItem.path !== '/cert') {
      //   // $router.push(selectedItem.path + '')
        // location.href = selectedItem.path + ''
        // ctx.emit('close')
      // }
      if (selectedItem.path === '/about-us') {
        location.href = selectedItem.path + ''
        ctx.emit('close')
      }
    }
    return {
      brands,
      selected,
      getBrands,
      brandsData,
      navCertData,
      handleSelected
    }
  }
}
</script>

<style lang="less" scoped>
.mob-navbar-expand {
  width: 100%;
  // width: 100vw;
  // height: calc(100vh - 60px);
  height: calc(100vh - 30px);
  background-color: #fff;
  position: fixed;
  top: 60px;
  z-index: 9999;
  border-top: @border;
  // overflow: hidden;
}
.navbar-menu {
  width: 120px;
  height: calc(100vh - 30px);
  background-color: @bg-color;
  li {
    padding: 12px;
    cursor: pointer;
  }
  li.active {
    background-color: #fff;
    position: relative;
  }
  li.active::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 3px;
    background-color: @theme-color;
  }
}
.navbar-content {
  flex: 1;
  padding: 12px 24px;
  overflow: auto;
  height: calc(100vh - 60px);
}
.navbar-content-brands {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: @border;
  &:last-child {
    border-bottom: unset;
  }
}
.navbar-content-title {
  margin-bottom: 6px;
}
.navbar-content-brands-item {
  a {
    display: inline-block;
    padding: 3px 0;
    color: @text-color-l;
  }
}

.tools-title,
.partner-title {
  margin-bottom: 12px;
}
.tools-group {
  margin-bottom: 12px;
  .tool-item a {
    display: inline-block;
    padding: 6px 0;
  }
}
.tools-group-last {
  border-top: @border;
  border-bottom: @border;
  padding-top: 12px;
  padding-bottom: 12px;
}
.monitor-item a,
.partner-container-row a {
  display: inline-block;
  padding: 6px 0;
  font-weight: bold;
}
.partner-container-row a {
  font-weight: normal;
}
.mobile-help-navbar {
  display: block;
  & /deep/ .help-media__col {
    margin-bottom: 24px;
  }
  & /deep/ .help-media__col ~ .help-media__col {
    margin-left: 0;
    margin-bottom: 0;
  }
  & /deep/ .help-media__col li > a {
    padding: 6px 0;
  }
}

.col-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}
.navbar-viewer {
  flex: 1;
  padding: 12px 12px 74px 24px;
  overflow: hidden auto;
  // width: calc(100% - 48px - 120px);
  height: calc(100vh - 60px);
  &::-webkit-scrollbar {
    width: 0;
  }
}
.nvabr-expand-col:not(:first-child) {
  margin-top: 24px;
}
.ssl-item {
  margin-top: 24px;
  color: @text-color-l;
  a {
    position: relative;
  }
  &:hover a::before {
    width: 100%;
  }
  a::before {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: .3s ease-out;
    background-color: @theme-color;
  }
}

.partner,
.digital-signature {
  img {
    width: 60px;
  }
  .nvabr-expand-col {
    display: block;
    margin-top: 24px;
  }
  .nvabr-expand-col:first-child {
    margin-top: 0;
  }
}

.tools {
  .brand-group {
    margin-right: 24px;
  }
}

@media screen and (max-width: 550px){
  .navbar-content-group-item-recommend {
    columns: 2;
  }
}
@media screen and (max-width: 450px){
  .navbar-content-group-item-recommend {
    columns: unset;
  }
}
</style>

import { render, staticRenderFns } from "./ComplianceLeft.vue?vue&type=template&id=665a6780&scoped=true&"
import script from "./ComplianceLeft.vue?vue&type=script&lang=js&"
export * from "./ComplianceLeft.vue?vue&type=script&lang=js&"
import style0 from "./ComplianceLeft.vue?vue&type=style&index=0&id=665a6780&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665a6780",
  null
  
)

export default component.exports
// const isProduct = process.env.NODE_ENV !== 'production'

const brands = [
  { name: 'digicert', url: '/logo/digicert.svg' },
  { name: 'globalsign', url: '/logo/globalsign.svg' },
  { name: 'geotrust', url: '/logo/geotrust.svg' },
  { name: 'sectigo', url: '/logo/sectigo.svg' },
  // { name: 'entrust', url: '/logo/entrust.svg' },
  { name: 'alphassl', url: '/logo/alphassl.svg' },
  { name: 'bitcert', url: '/logo/bitcert.svg' },
  { name: 'certum', url: '/logo/certum.svg' },
  { name: 'positivessl', url: '/logo/positivessl.svg' },
  { name: 'rapidssl', url: '/logo/rapidssl.svg' },
  { name: 'thawte', url: '/logo/thawte.svg' },
  { name: 'cfca', url: '/logo/cfca.svg' }
]

const ourServices = {
  title: '我们的服务',
  data: [
    {
      title: '快速申请',
      summary: '支持在线自动 CSR 生成，审核签发流程全自动。',
      icon: '/icon/home/our-services/kssq.png'
    },
    {
      title: '集中管理',
      summary: '支持上传任意机构签发的国际标准 SSL 证书，方便您对所有证书进行统一管理，集中监控每个证书的有效期限。',
      icon: '/icon/home/our-services/jzgl.png'
    },
    {
      title: '私钥托管',
      summary: '在线生成 CSR 并且设置了私钥密码的证书，将密码作为证书进行加密托管，保证用户的信息安全。',
      icon: '/icon/home/our-services/sytg.png'
    },
    {
      title: '轻松部署',
      summary: '支持快速在云资源中部署 SSL 证书，目前负载均衡、CDN 已支持 SSL 证书部署，您可以更快捷地获得数据安全。',
      icon: '/icon/home/our-services/qsbs.png'
    },
    {
      title: '品牌多样',
      summary: '与全球范围内多家知名证书服务机构合作，提供不同品牌，不同类型的证书购买签发服务，满足用户不同场景下的证书购买需求。',
      icon: '/icon/home/our-services/ppdy.png'
    },
    {
      title: '国密支持',
      summary: '有 CFCA 品牌 SSL 证书支持 SM2 国密算法，满足政府机构、事业单位、大型国企、金融银行等行业客户的国产化改造和国密算法合规需求。',
      icon: '/icon/home/our-services/gmzc.png'
    },
  ]
}

export default {
  "privateInfo": {
    "tel": "400-128-0988"
  },
  // "common": {
  //   "day": "天",
  //   "year": "年",
  //   "price": "价格",
  //   "originalPrice": "市场价",
  //   "minute": "分钟",
  //   "currency": "人民币",
  //   "currencySymbol": "￥",
  //   "productName": "产品名称",
  //   "trust": "信任等级",
  //   "issueTime": "签发时间",
  //   "validity": "有效期",
  //   "secures": "保护",
  //   "publicKeyAlgorithm": "加密算法",
  //   "symmetricEncryption": "加密位数",
  //   "publicKeyLength": "公钥长度",
  //   "rootCa": "根证书",
  //   "refundSince": "退款期限",
  //   "reIssue": "证书重签",
  //   "supportMulti": "支持多域名",
  //   "warranty": "保修金额",
  //   "buy": "购买",
  //   "buyNow": "立即购买",
  //   "login": "登录",
  //   "register": "注册",
  //   "compatible": "浏览器兼容",
  //   "submit": "提交",
  //   "danger": "不安全",
  //   "discount": "折扣",
  //   "privatekeyontoken": "硬件证书",
  //   "addDomains": "增加域名",
  //   "dv": "域名验证",
  //   "ov": "企业验证",
  //   "ev": "扩展验证"
  // },
  "productType": {
    "email-signing": "邮件签名证书",
    "code-signing": "微软标准代码签名证书",
    "ev-code-signing": "微软 EV 代码签名证书",
    "document-signing": "文件签名证书",
    "single-domain": "单域名证书",
    "multi-domain": "多域名证书",
    "wildcard": "通配符证书",
    "domain-validation": "域名级证书",
    "organization-validation": "企业级证书",
    "extended-validation": "增强级证书",
    "other": "其他数字证书"
  },
  "navbar": {
    contactUs: '联系我们',
    help: '帮助',
    login: '登录',
    registe: '注册',
    "options": [
      {
        "path": "/ssl",
        "text": "SSL/TLS 证书",
        "expand": "ssl"
      },
      {
        "path": "/cert",
        "text": "数字签名",
        "expand": "cert"
      },
      // ...[isProduct ? {
      //   "path": "/monitor",
      //   "text": "证书监控"
      // } : {
      //   "path": "/certhub",
      //   "text": "证书管理",
      //   "expand": "certhub"
      // }],
      {
        "path": "/certhub",
        "text": "证书管理",
        "expand": "certhub"
      },
      // {
      //   "path": "/compliance",
      //   "text": "解决方案",
      //   "expand": "compliance"
      // },
      {
        "path": "/tools/index",
        "text": "证书工具",
        // "expand": isProduct ? '' : 'partner'
        // "expand": 'partner'
      },
      {
        "path": "/partner/reseller",
        "text": "合作代理",
        // "expand": isProduct ? '' : 'partner'
        // "expand": 'partner'
      }
      // {
      //   // "path": "http://help.certbase.com",
      //   // "path": isProduct ? 'http://help.certbase.com' : '/help',
      //   "path": '/help',
      //   "text": "帮助教程",
      //   // "expand": isProduct ? '' : 'help'
      //   "expand": 'help'
      // },
      // {
      //   "path": "/compliance",
      //   "text": "等保合规"
      // },
      // {
      //   "path": "/about-us",
      //   "text": "关于我们"
      // }
    ],
    "expand": {
      "nav": [
        {
          "text": "证书品牌",
          "index": "brands"
        },
        {
          "text": "域名级（DV）",
          "index": "domain-validation"
        },
        {
          "text": "企业级（OV）",
          "index": "organization-validation"
        },
        {
          "text": "增强级（EV）",
          "index": "extended-validation"
        },
        {
          "text": "多域名（SAN/UCC）",
          "index": "multi-domain"
        },
        {
          "text": "通配符（Wildcard）",
          "index": "wildcard"
        },
        {
          "text": "公网 IP 证书",
          "index": "public-ip-address"
        }
      ],
      "content": {
        "brands": [],
        "domain-validation": [],
        "recommend": [],
        "help": [
          {
            "title": "Installation",
            "options": [
              {
                "text": "DNS",
                "path": "/sectigo/dv"
              }
            ]
          }
        ],
        "other": [
          {
            "title": "Other",
            "options": [
              {
                "text": "change password",
                "path": "/sectigo/dv"
              }
            ]
          }
        ]
      },
      "certs": [
        {
          "title": "Sectigo",
          "options": [
            {
              "text": "Sectigo Code Signing",
              "path": "/"
            }
          ]
        },
        {
          "title": "Sectigo",
          "options": [
            {
              "text": "Sectigo Code Signing",
              "path": "/"
            }
          ]
        },
        {
          "title": "Sectigo",
          "options": [
            {
              "text": "Sectigo Code Signing",
              "path": "/"
            }
          ]
        },
        {
          "title": "Sectigo",
          "options": [
            {
              "text": "Sectigo Code Signing",
              "path": "/"
            }
          ]
        },
        {
          "title": "Sectigo",
          "options": [
            {
              "text": "Sectigo Code Signing",
              "path": "/"
            }
          ]
        }
      ]
    },
  },
  "footer": {
    "media": [
      {
        "icon": "/icon/support__1.svg",
        "title": "快速签发",
        "content": "3-5分钟"
      },
      {
        "icon": "/icon/support__2.svg",
        "title": "价格匹配",
        "content": "100% 保证"
      },
      {
        "icon": "/icon/support__3.svg",
        "title": "免费证书",
        "content": "免费30天试用"
      },
      {
        "icon": "/icon/support__4.svg",
        "title": "退款保证",
        "content": "30天无条件退款"
      },
      {
        "icon": "/icon/support__5.svg",
        "title": "24/7 技术支持",
        "content": "多渠道服务支持"
      }
    ],
    "aside": [
      {
        "label": "400-128-0988",
        "icon": "ri-phone-line"
      },
      {
        "label": "177-0811-9188",
        "icon": "ri-smartphone-line"
      },
      // {
      //   "label": "成都市天府新区华府大道1号蓝润置地T3",
      //   "icon": "ri-map-pin-line"
      // },
      {
        "label": "时间：周一到周日: 早9点 – 晚12点",
        "icon": "ri-time-line"
      },
      {
        "label": "support@sslcertificateshop.com",
        "icon": "ri-mail-send-line"
      }
    ],
    "content": [
      {
        "title": "证书类型",
        "options": [
          {
            "path": "/ssl/domain-validation",
            "text": "域名级（DV）"
          },
          {
            "path": "/ssl/organization-validation",
            "text": "企业级（OV）"
          },
          {
            "path": "/ssl/extended-validation",
            "text": "增强级（EV）"
          },
          {
            "path": "/ssl/multi-domain",
            "text": "多域名（SAN/UCC）"
          },
          {
            "path": "/ssl/wildcard",
            "text": "通配符（Wildcard）"
          },
          {
            "path": "/digital-signing/code-signing",
            "text": "微软代码签名"
          },
          {
            "path": "/digital-signing/code-signing",
            "text": "微软 EV 代码签名证书"
          },
          {
            "path": "/digital-signing/document-signing",
            "text": "文件签名证书（AATL）"
          },
          {
            "path": "/digital-signing/email-signing",
            "text": "邮件签名和加密证书"
          },
          {
            "path": "/ssl/public-ip-address",
            "text": "公网 IP 证书"
          }
        ]
      },
      {
        "title": "证书品牌",
        "options": [
          {
            "path": "/brand/securesite",
            "text": "Secure Site"
          },
          {
            "path": "/brand/securesitepro",
            "text": "Secure Site Pro"
          },
          {
            "path": "/brand/godaddy",
            "text": "Godaddy"
          },
          {
            "path": "/brand/cfca",
            "text": "CFCA"
          },
          {
            "path": "/brand/digicert",
            "text": "DigiCert"
          },
          {
            "path": "/brand/globalsign",
            "text": "GlobalSign"
          },
          {
            "path": "/brand/geotrust",
            "text": "GeoTrust"
          },
          {
            "path": "/brand/sectigo",
            "text": "Sectigo"
          },
          {
            "path": "/brand/thawte",
            "text": "Thawte"
          },
          {
            "path": "/brand/rapidssl",
            "text": "RapidSSL"
          },
          {
            "path": "/brand/alphassl",
            "text": "AlphaSSL"
          }
        ]
      },
      {
        "title": "24/7 帮助中心",
        "options": [
          {
            "path": "",
            "text": "通知公告"
          },
          {
            "path": "",
            "text": "安装教程 "
          },
          {
            "path": "",
            "text": "故障排除"
          },
          {
            "path": "",
            "text": "常见问题"
          },
          {
            "path": "",
            "text": "代码签名使用帮助"
          },
          {
            "path": "",
            "text": "安全优化"
          }
        ]
      },
      {
        "title": "关于 Certbase",
        "options": [
          {
            "path": "/about-us",
            "text": "关于我们"
          },
          {
            "path": "/contact-us",
            "text": "联系我们"
          },
          {
            "path": "/partner-program",
            "text": "合作伙伴"
          },
          {
            "path": "",
            "text": "博客"
          },
          {
            "path": "",
            "text": "账户登录"
          },
          {
            "path": "",
            "text": "信任中心"
          }
        ]
      },
      {
        "title": "声明",
        "options": [
          {
            "path": "",
            "text": "我们接受支付宝、微信支付、对公转账支付"
          },
          {
            "path": "",
            "text": "本站所有产品价格含税，可开具增值税普通发票或者增值税专用发票（税率 6% ）。"
          },
          {
            "path": "",
            "text": "纸质发票顺丰速递包邮。"
          },
          {
            "path": "",
            "text": "Certbase 其微标是成都汇智云安信息科技有限公司的注册商标。DigiCert，其徽标及CertCentral是DigiCert，Inc.的注册商标。Symantec是Broadcom Inc.的商标，Norton和Checkmark Logo是NortonLifeLock Inc.的商标，根据许可使用。其他名称可能是其各自所有者的商标。"
          }
        ]
      }
    ],
    "signature": [
      {
        "brand": "geotrust",
        "coverImgUrl": "/images/geotrustsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "globalsign",
        "coverImgUrl": "/images/globalsign-site-seal.svg",
        "signature": ""
      },
      {
        "brand": "rapidssl",
        "coverImgUrl": "/images/rapidsslsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "sectigo",
        "coverImgUrl": "/images/sectigositeseal.svg",
        "signature": ""
      },
      {
        "brand": "thawte",
        "coverImgUrl": "/images/thawtesiteseal.svg",
        "signature": ""
      },
      {
        "brand": "digicert",
        "coverImgUrl": "/images/digicert-n.svg",
        "signature": ""
      },
      {
        "brand": "norton",
        "coverImgUrl": "/images/nortonsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "securesite",
        "coverImgUrl": "/images/nortonsiteseal.svg",
        "signature": ""
      },
      {
        "brand": "cfca",
        "coverImgUrl": "/images/cfca.svg",
        "signature": ""
      },
      {
        "brand": "godaddy",
        "coverImgUrl": "/images/godaddy.svg",
        "signature": ""
      }
    ],
    "copyright": [
      "版权所有© $year 成都汇智云安信息科技有限公司",
      "统一社会信用代码:91510100MA61R0XJ15。中华人民共和国增值电信业务经营许可证编号: 川B2-20180413。ICP证: <a href=\"https://beian.miit.gov.cn/\">蜀ICP备16002107号-3</a>"
    ],
    "license": [
      {
        "text": "服务条款",
        "path": "/terms"
      },
      {
        "text": "隐私政策",
        "path": "/privacy-policy"
      },
      {
        "text": "注册协议",
        "path": "/member-aggreement"
      },
      {
        "text": "退款政策",
        "path": "/refund-policy"
      }
    ],
    "declareContent": {
      "title": "声明",
      // "content": "DigiCert，其徽标及CertCentral是DigiCert，Inc.的注册商标。Symantec是Broadcom Inc.的商标，Norton和Checkmark Logo是NortonLifeLock Inc.的商标，根据许可使用。其他名称可能是其各自所有者的商标。",
      "options": [
        {
          "path": "",
          "text": "我们接受支付宝、微信支付、对公转账支付"
        },
        {
          "path": "",
          "text": "本站所有产品价格含税，可开具增值税普通发票或者增值税专用发票（税率 6% ）。"
        },
        {
          "path": "",
          "text": "纸质发票顺丰速递包邮。"
        },
        {
          "path": "",
          "text": "Certbase 其微标是成都汇智云安信息科技有限公司的注册商标。DigiCert，其徽标及CertCentral是DigiCert，Inc.的注册商标。Symantec是Broadcom Inc.的商标，Norton和Checkmark Logo是NortonLifeLock Inc.的商标，根据许可使用。其他名称可能是其各自所有者的商标。"
        }
      ]
    },
    "options": [
      [
        {
          "title": "GlobalSign",
          "options": [
            {
              "alias": "globalsign-domainssl",
              "title": "域名级 TLS 证书",
              "href": ""
            },
            {
              "alias": "globalsign-domainssl-wildcard",
              "title": "域名级通配符 TLS 证书",
              "href": ""
            },
            {
              "alias": "globalsign-organizationssl",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "globalsign-extendedssl",
              "title": "增强级 TLS 证书",
              "href": ""
            },
            {
              "alias": "globalsign-code-signing",
              "title": "微软标准代码签名证书",
              "href": ""
            },
            {
              "alias": "globalsign-ev-code-signing",
              "title": "微软 EV 代码签名证书",
              "href": ""
            },
            {
              "alias": "",
              "title": "PDF 文档签名证书",
              "href": ""
            }
          ]
        },
        {
          "title": "AlphaSSL",
          "options": [
            {
              "alias": "alphassl",
              "title": "域名级 TLS 证书",
              "href": ""
            },
            {
              "alias": "alphassl-wildcard",
              "title": "域名级通配符 TLS 证书",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "DigiCert",
          "options": [
            {
              "alias": "digicert-basic-ov",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "digicert-basic-ev",
              "title": "增强级 TLS 证书",
              "href": ""
            },
            {
              "alias": "digicert-code-signing",
              "title": "微软标准代码签名证书",
              "href": ""
            },
            {
              "alias": "digicert-ev-code-signing",
              "title": "微软 EV 代码签名证书",
              "href": ""
            },
            {
              "alias": "digicert-document-signing-org-2000",
              "title": "PDF 文档签名证书",
              "href": ""
            },
            {
              "alias": "digicert-smime-premium",
              "title": "企业级邮件加密证书",
              "href": ""
            }
          ]
        },
        {
          "title": "RapidSSL",
          "options": [
            {
              "alias": "rapidssl",
              "title": "单域名 TLS 证书",
              "href": ""
            },
            {
              "alias": "rapidssl-wildcard",
              "title": "域名级通配符 TLS 证书",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "Secure Site",
          "options": [
            {
              "alias": "secure-site",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "secure-site-ev",
              "title": "增强级 TLS 证书",
              "href": ""
            }
          ]
        },
        {
          "title": "Thawte",
          "options": [
            {
              "alias": "thawte-ssl-123",
              "title": "域名级 TLS 证书",
              "href": ""
            },
            {
              "alias": "thawte-web-server",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "thawte-web-server-ev",
              "title": "增强级 TLS 证书",
              "href": ""
            }
          ]
        },
        {
          "title": "GeoTrust",
          "options": [
            {
              "alias": "geotrust-standard-dv",
              "title": "域名级 TLS 证书 ",
              "href": ""
            },
            {
              "alias": "geotrust-true-businessid",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "geotrust-true-businessid-ev",
              "title": "增强级 TLS 证书",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "Secure Site Pro",
          "options": [
            {
              "alias": "secure-site-pro",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "secure-site-pro-ev",
              "title": "增强级 TLS 证书",
              "href": ""
            }
          ]
        },
        {
          "title": "PositiveSSL",
          "options": [
            {
              "alias": "positivessl-dv",
              "title": "域名级 TLS 证书",
              "href": ""
            }
          ]
        },
        {
          "title": "Sectigo",
          "options": [
            {
              "alias": "sectigo-dv",
              "title": "域名级 TLS 证书",
              "href": ""
            },
            {
              "alias": "sectigo-ov",
              "title": "企业级 TLS 证书",
              "href": ""
            },
            {
              "alias": "sectigo-ev",
              "title": "增强级 TLS 证书",
              "href": ""
            }
          ]
        }
      ],
      [
        {
          "title": "Godaddy",
          "options": [
            {
              "alias": "godaddy-dv",
              "title": "域名级单域名 TLS证书"
            },
            {
              "alias": "godaddy-wild",
              "title": "域名级通配符 TLS 证书"
            },
            {
              "alias": "godaddy-mutil",
              "title": "域名级多域名 TLS 证书"
            }
          ]
        },
        {
          "title": "CFCA",
          "options": [
            {
              "alias": "cfca-ov",
              "title": "企业级 TLS 证书"
            },
            {
              "alias": "cfca-ev",
              "title": "增强级 TLS 证书"
            },
            {
              "alias": "cfca-sm",
              "title": "国密 TLS 证书"
            }
          ]
        }
      ]
    ]
  },
  "codeSigning": {
    "title": "您可以在这些平台上使用代码签名证书",
    "summary": [
      "为您的应用程序和软件平台选择 $productName"
    ],
    "table": [
      {
        "offset": -444,
        "title": "微软代码签名",
        "content": "对 32 位或 64 位用户模式（.exe、.cab、.dll、.ocx、.msi、.xpi 和.xap 文件） 和内核模式软件进行数字签名。Microsoft Windows 徽标计划提供商。"
      },
      {
        "offset": -744,
        "title": "Java代码签名",
        "content": "对于桌面和移动设备 Java 应用程序，可以对 .jar 文件和 Netscape Object Signing 进行数字签名。为 Java Runtime Environment (JRE) 所识别。"
      },
      {
        "offset": -894,
        "title": "Microsoft® Office 和 VBA代码签名",
        "content": "对 VBA 对象和宏进行数字签名。可用于使用 VBA 的 Microsoft Office和第三方应用程序。"
      },
      {
        "offset": -1050,
        "title": "Adobe® AIR®代码签名",
        "content": "对 .air 或 .airi 文件进行数字签名。所有基于 AIR 的应用程序所必需的。"
      },
      {
        "offset": -600,
        "title": "Android 代码签名",
        "content": "对 VBA 对象和宏进行数字签名。可用于使用 VBA 的 Microsoft Office和第三方应用程序。"
      },
      {
        "offset": -1194,
        "title": "微软手机代码签名",
        "content": "对Windows Phone和Xbox 360的代码进行数字签名。需要签名才能将应用程序发布到 Microsoft App Hub。"
      },
      {
        "offset": -1344,
        "title": "驱动程序签名",
        "content": "通过完成 Windows 硬件兼容性计划的要求，对驱动程序进行签名。"
      }
    ]
  },
  "home": {
    brands,
    ourServices,
    "pageTitle": "中立TLS//PKI 提供商 - 加密、身份验证、自动化 - Certbase",
    "contents": "Certbase 是领先的可扩展 PKI 和物联网解决方案提供商，用于加密、身份验证和自动化证书生命周期管理。",
    "banner": {
      "title": "中立TLS//PKI 提供商",
      "summary": "数字证书全生命周期管理平台，提供 SSL 证书和私有 CA 服务，支持服务端、客户端等多场景的证书签发和管理能力，为您的网站、移动应用（App）、小程序及企业信息化应用提供数据传输保护和可信身份认证",
      "btn": [
        "购买证书",
        "管理证书"
      ]
    },
    "fixedBanner": [
      {
        "icon": "/icon/service__input_domain.svg",
        "title": "选择证书输入信息",
        "subtitle": "快速申请 SSL/TLS 证书和各种签名证书"
      },
      {
        "icon": "/icon/service__create_order.svg",
        "title": "提交申请完成验证",
        "subtitle": "自动创建 CSR 并完成 DV、OV/EV 认证"
      },
      {
        "icon": "/icon/service__download.svg",
        "title": "证书签发下载使用",
        "subtitle": "安装证书可以保护网站、软件、邮件、文档文件"
      },
      {
        "icon": "/icon/service__ Hardware.svg",
        "title": "可视化证书管理",
        "subtitle": "自动发现、管理和跟踪您的 SSL/TLS 证书"
      }
    ],
    "subbanner": {
      "left": {
        "icon": "/icon/industry__ai.svg",
        "iconText": "适用行业"
      },
      "right": [
        {
          "icon": "/icon/industry__medical.svg",
          "iconText": "医疗健康"
        },
        {
          "icon": "/icon/industry__ai.svg",
          "iconText": "教育"
        },
        {
          "icon": "/icon/industry__finance.svg",
          "iconText": "金融"
        },
        {
          "icon": "/icon/industry__service.svg",
          "iconText": "证券"
        },
        {
          "icon": "/icon/industry__iot.svg",
          "iconText": "物联网"
        },
        {
          "icon": "/icon/industry__app.svg",
          "iconText": "保险"
        },
        {
          "icon": "/icon/industry__traffic.svg",
          "iconText": "电商"
        },
        {
          "icon": "/icon/industry__send_data.svg",
          "iconText": "支付"
        },
        {
          "icon": "/icon/industry__append_1.svg",
          "iconText": "政务"
        },
        {
          "icon": "/icon/industry__append_2.svg",
          "iconText": "互联网"
        }
      ]
    },
    "product": {
      "title": "SSL/TLS 证书",
      "btn": "立即购买",
      "data": [
        {
          "title": "域名级证书（DV）",
          "subtitle": "适用于普通用户",
          "summary": "最实惠的 SSL/TLS 证书，它使用基本域名验证来证明域名的所有权。验证是通过名为 DCV 的验证电子邮件进行的（也可以使用 DNS 和 FILE 作为替代方法）。DV 证书的最大优点是发行时间短，您可以在几分钟内获得 DV 证书。",
          "price": "168 元/年  起"
        },
        {
          "title": "企业级证书（OV）",
          "subtitle": "适用于商业用户",
          "summary": "OV SSL/TLS 证书比 DV 证书具有更高的信任度，对于政府、学术机构、无盈利组织或涉及信息交互的网站来说，一张 OV SSL 证书才能向您的用户证明您的网站真实可靠、安全可信，赢得他们的信赖，可以帮助您获得更多的客户。",
          "price": "1200 元/年  起"
        },
        {
          "title": "增强级证书（EV）",
          "subtitle": "适用于需要用户高度信任的网站",
          "summary": "EV SSL/TLS 证书是最高级别的认证版本。通过在 URL 旁边显示公司名称，用户可以轻松地看到自己在正确的网站上，而不是钓鱼网站。意味着您的网站在确定域名所有权后，也对企业身份、法律地位和地址进行过了深入的审核验证。",
          "price": "1800 元/年  起"
        }
      ]
    },
    "whatTls": {
      "title": "什么是 SSL/TLS 证书",
      "topTitle": "证书的优势",
      "more": "了解更多信息",
      "data": [
        {
          "title": "加密",
          "imgUrl": "/icon/encrypt.svg",
          "summary": "使用证书会对传输的所交换的数据进行加密，防止用户信息被监听、修改、窃取等"
        },
        {
          "title": "身份认证",
          "imgUrl": "/icon/user-auth.svg",
          "summary": "因为有了证书，可以证明用户可与目标网站通信，建立用户信任，进而带来其他商业效益。"
        },
        {
          "title": "数据完整",
          "imgUrl": "/icon/full-data.svg",
          "summary": "数据在传输过程中无法被修改或损坏，也不会被检测，用户发送的信息会完整的传输到服务端"
        }
      ]
    },
    "certifyUs": {
      "title": "为什么选择我们",
      "topTitle": "Certbase 的优势",
      "data": [
        {
          "path": "/about-us",
          "imgUrl": "/icon/publisher.svg",
          "summary": "我们是 SSL/TLS 证书的大型发行商之一"
        },
        {
          "path": "/references",
          "imgUrl": "/icon/partner.svg",
          "summary": "这些伟大的企业信任我们，因为我们是可靠的合作伙伴"
        },
        {
          "path": "/contact-us",
          "imgUrl": "/icon/code.svg",
          "summary": "我们不间断的提供关于数字证书的技术支持"
        },
        {
          "path": "/bank-details-and-payment-methods",
          "imgUrl": "/icon/payment.svg",
          "summary": "支持多种在线支付方式，节约付款所需时间"
        }
      ]
    },
    "getCert": {
      "title": "立即获取 SSL/TLS 证书",
      "data": [
        {
          "title": "使用我们的购买证书页面选择证书",
          "content": "无论您需要什么品牌，需要什么类型的 SSL/TLS 证书，我们都能帮助您为项目选择最合适的 SSL/TLS 证书"
        },
        {
          "title": "我们将帮助您安装 SSL/TLS 证书",
          "content": "我们的工作人员可以在工作日或者非工作日为您提供免费的安装服务。预约情况下，可以做到7*24服务。"
        },
        {
          "title": "您的访客享受着安全网站所带来的好处",
          "content": "使用汇智云安的 SSL/TLS 证书，可以保护您网站的访问者。除了防止数据窃听外，证书还具有身份认证功能。使用 EV 证书可以在浏览器地址栏更只管的展示网站所有者信息，可以立即确认网站身份。"
        }
      ],
      "banner": {
        "title": "即可获取 SSL/TLS 证书",
        "btn": "立即购买"
      }
    },
    "contrast": {
      "title": "企业级（OV）、域名级（DV）、增强级（EV）证书的区别",
      "theads": [
        "验证类型",
        "企业级（OV）",
        "域名级（DV）",
        "增强级（EV）"
      ],
      "options": [
        [
          "适合场景",
          "适合企业应用（OA、CRM、ERP、HRM等）、企业官网、电商",
          "适合小微企业/API服务/个人网站",
          "适合金融平台、大中企业、政府机关"
        ],
        [
          "场景特点",
          "提升系统安全，确保敏感信息不被劫持，增强企业诚信力和用户信赖感",
          "使用SSL证书进行信息传输的高强度加密，可有效杜绝信息劫持",
          "最大程度保障信息安全和网站公信力，大网站标配"
        ],
        [
          "验证内容",
          "验证企业身份",
          "验证域名归属",
          "最高验证级别"
        ],
        [
          "部署形态",
          "普通加锁标记，证书中展示企业信息",
          "普通加锁标记",
          "绿色安全地址栏"
        ],
        [
          "主流浏览器（电脑&移动端）兼容",
          "100%",
          "100%",
          "100%"
        ],
        [
          "服务器部署限制",
          "不限",
          "不限",
          "不限"
        ],
        [
          "加密强度",
          "最强SHA2 & ECC加密算法",
          "最强SHA2 & ECC加密算法",
          "最强SHA2 & ECC加密算法"
        ],
        [
          "签发时间",
          "1-2个工作日",
          "10 分钟",
          "1-3个工作日"
        ],
        [
          "一次性购买年限",
          "6年",
          "6年",
          "6年"
        ],
        [
          "无理由退款期限",
          "签发30天",
          "签发30天",
          "签发30天"
        ],
        [
          "赔付保证金",
          "高达125万美元",
          "高达50万美元",
          "高达175万美元"
        ]
      ]
    },
    "features": {
      "title": "现代化证书管理",
      "data": [
        {
          "title": "多年证书计划",
          "icon": "/icon/platform__1.svg",
          "summary": "订购多年期证书将为您节省很多钱！ 多年期计划简化了证书管理和证书续费流程，您最多可以订购6年期证书。每年会收到一张新的证书。"
        },
        {
          "title": "简化部署流程",
          "icon": "/icon/platform__2.svg",
          "summary": "支持一键下载所有格式以及推送证书到各个公有云(如阿里云、、Amazon AWS等)，轻松实现自动化，减少证书部署难度，提升运维效率。"
        },
        {
          "title": "国际信赖品牌",
          "icon": "/icon/platform__3.svg",
          "summary": "直接对接国际/国内最值得信赖的数字证书颁发机构(CA)，价格更具竞争力。涵盖RSA/ECC/SM2主流算法证书产品，满足企业各类业务场景。"
        },
        {
          "title": "证书云端监控",
          "icon": "/icon/platform__4.svg",
          "summary": "Certhub 可自动导入任意网站的SSL证书，方便用户统一监控管理，支持各个品牌证书的一键续费，降低运维成本，节省客户时间。"
        }
      ]
    },
    "globalView": {
      "title": "深受用户信任",
      "globalLabel": "客户遍布全球",
      "cnLabel": "深耕国内",
      "globalImgUrl": "/images/g-map.png",
      "cnImgUrl": "/images/cn-map.png"
    }
  },
  "about": {
    "pageTitle": "关于我们",
    "title": "关于我们",
    "summary": [
      "我们为您提供网络安全服务以及全面的数据安全解决方案",
      "Certbase 其微标是成都汇智云安信息科技有限公司的注册商标。",
      "Certbase 是全球首屈一指的高保证数字证书提供商，为新兴物联网市场提供值得信赖的 SSL/TLS、私有和托管 PKI 部署以及（IoT）设备证书。从我们成立近六年以来，我们一直在寻找更好的方法、更好地为互联网提供身份验证、信息加密、漏洞检测和终端安全。",
      "Certbase 是全球 SSL/TLS 证书的大型发行商之一。我们的使命是建立信任的在线网络，因为我们充分认识到：只有建立一个可信的互联网，才能发挥其巨大潜能为人类沟通和商业活动造福。Certbase 在全力以赴的去帮助个人、网商、中小企业和大企业建立在线信任。目前，我们提供域名级（DV），企业级（OV）和增强级（EV）SSL/TLS 证书，通配符（Wildcard）、多域名（SAN）、代码签名（Code Signing）、文件签名（Document Signing）、IP 地址证书和S/MIME邮件签名证书。我们是Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供最低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。"
    ],
    "banner": {
      "title": "我们成功的诀窍",
      "imgUrl": "https://certbase.com/images/about-us-banner.svg",
      "summary": [
        {
          "title": "替客户着想",
          "content": "客户应该了解证书加密解密的工作原理，但是不应该也没有必要为客户不需要知道的更详细的技术原理而头疼"
        },
        {
          "title": "合格的支持",
          "content": "所有 Certbase 员工均通过了专业的数字证书销售培训。他们完全有能力为您提供建议让您正确选择证书并帮助您进行部署。"
        },
        {
          "title": "可靠的CA",
          "content": "我们一直只专注于最优质的产品。我们提供受欢迎的 GlobalSign 证书以及 DigiCert（全球最大的认证机构）提供的证书。"
        }
      ]
    },
    "contents": [
      {
        "layout": "",
        "src": "",
        "title": "我们很自豪的成为全球多个大型数字证书颁发机构的合作伙伴",
        "content": "我们是 Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供最低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。感谢您选择我们公司，我们将继续改善我们的服务和质量。"
      },
      {
        "layout": "",
        "src": "/logo/globalsign.svg",
        "title": "GlobalSign 授权合作伙伴",
        "content": "GlobalSign 是一个具有高可信度并且发展良好的证书管理机构和 SSL 提供商。 作为公众信任服务行业的领头羊，GlobalSign 自 1996 年起开始颁发可信赖的数字证书，服务范围从一些已经广泛普及的公众根中提供公众信任。GlobalSign 提供全面丰富的产品，包括域名验证级(DV)SSL证书、企业验证级(OV)SSL证书、扩展验证级(EV)SSL 证书、代码签名证书等等。这些可信任的根能够为所有的操作系统、主要网站浏览器、服务器、e-mail 客户端以及互联网应用程序等进行真实性识别，而且还包括了一些特别的移动设备装置，如智能电话（Smartphone ）以及主要的 PDA 和移动电话。"
      },
      {
        "layout": "",
        "src": "/logo/sectigo.svg",
        "title": "Sectigo 授权合作伙伴",
        "content": "Sectigo（原Comodo CA）成立于1998年，是全球优秀的网络安全服务提供商和SSL证书服务商之一，Sectigo 证书产品类型丰富，满足各类网站不同的安全需求，极具性价比，深受中小型企业的欢迎。Sectigo 拥有超过20年的数字证书行业经验，证书发行量全球第一，迄今为止已发出超过1亿份证书，为所有网站和移动应用提供各个类型的数字证书安全解决方案。"
      },
      {
        "layout": "",
        "src": "/logo/digicert.svg",
        "title": "DigiCert 授权合作伙伴",
        "content": "DigiCert，成立于2003年，是全球领先的数字证书提供商。10多年来一直只专注于为用户提供SSL证书和代码签名证书，全球领先的银行、电子商务、技术、医疗保健与制造企业依靠DigiCert为其最有价值的在线产品提供可扩展的加密与身份验证。在Web领域之外，DigiCert通过可扩展的、基于PKI的自动化解决方案进行创新，这些解决方案涉及为物联网（IoT）与其他新兴的互联市场提供身份、验证与加密。"
      },
      {
        "layout": "",
        "src": "/logo/geotrust.svg",
        "title": "GeoTrust 授权合作伙伴",
        "content": "GeoTrust在150多个国家和地区拥有大量客户，因此世界各地的企业信赖 GeoTrust SSL/TLS安全解决方案。自2001年以来，GeoTrust以合理的价格为各类规模的组织提供了企业级证书——从大型企业到单一站点的小型企业都有涵盖，以满足任何预算需求。GeoTrust 提供多种身份验证等级，能让企业经济高效地满足任何预算需求。GeoTrust品牌的SSL证书是一款优质、高性价比的产品。不限制服务器安装数量，全兼容流行的操作系统和浏览器等特点，使得GeoTrust已成为互联网、IT行业等企业的优选产品。"
      },
      {
        "layout": "",
        "src": "/logo/thawte.svg",
        "title": "Thawte 授权合作伙伴",
        "content": "Thawte 是在美国以外的第一个SSL证书颁发机构，目前在全球SSL市场中占有40%的份额Thawte的SSL证书发行量超过100万，服务于全球240多个国家。Thawte一直致力于为用户提供可靠的SSL证书和优质的服务。 Thawte证书的传统和质量已广为人知，访问Thawte证书保护的网站的客户对网站提供商充满信心。"
      },
      {
        "layout": "",
        "src": "/logo/rapidssl.svg",
        "title": "RapidSSL 授权合作伙伴",
        "content": "RapidSSL证书是GeoTrust证书品牌中的入门级便宜SSL证书品牌，目前属于DigiCert的子品牌，其根证书为DigiCert，只有两款DV SSL证书产品，相对Digicert其他子品牌的DV SSL证书，价格便宜很多，非常适合小型网站和个人用户申请安装。"
      }
    ]
  },
  "hardware": {
    "pageTitle": "加密机",
    "title": "硬件产品",
    "summary": "High-Performance Solutions To Boost The Cryptographic Processing Speed,We offer a wide range of silicon proven IP Cores for implementation on ASIC/SoC and FPGA technology that enables faster cryptographic processing.",
    "contents": [
      {
        "layout": "",
        "src": "/logo/digicert.svg",
        "title": "TLS Handshake Hardware Accelerator",
        "content": "The TLS handshake hardware accelerator is a secure connection engine that can be used to offload the compute intensive Public Key operations (Diffie-Helmann, Signature Generation and Verification).It combines a load dispatcher and a configurable amount of instances of the Public Key Crypto Engine (BA414EP) benefiting from all features supported (i.e. RSA/DH/DHE and ECDSA/ECDH/ECDHE/X.25519/X.448 and more). The efficient dispatching to several tenths of BA414EP instances helps reaching maximum system performance."
      },
      {
        "layout": "end",
        "src": "/logo/digicert.svg",
        "title": "Hardware Security Module (HSM) For Modern Systems",
        "content": "A hardware security module, or HSM, is a dedicated, standards-compliant cryptographic appliance designed to protect sensitive data in transit, in use, and at rest through the use of physical security measures, logical security controls, and strong encryption."
      },
      {
        "layout": "",
        "src": "",
        "title": "USB Hardware Encryption & Two-Factor Authentication Keys",
        "content": "USB dongles help secure your PC or laptop, preventing unauthorised access. They combine hardware-based authentication, public key cryptography to prevent unauthorised users from accessing compatible software and websites."
      }
    ]
  },
  "monitor": {
    "pageTitle": "证书监控",
    "banner": {
      "btn": "立即体验",
      "title": "证书监控 Certhub",
      "summary": "Certhub 是一个云平台，可自动发现，组织和跟踪整个企业中的所有 SSL/TLS 证书，是一款集多个 HTTPS 站点安全检测、证书有效期管理以及异常告警等功能于一体的系统。它提供了可视化评级图表和可交互的跨品牌证书管理仪表盘，支持 HTTPS 安全评级，证书品牌、CT日志监控、证书有效期、SSL 漏洞、PCI DSS & ATS 合规监控和集中管理；还能够及时地将异常情况通过邮件、短信进行告警通知，并根据评测结果，给出改造建议。"
    },
    "summary": {
      "title": "证书监控 Certhub 简介",
      "content": "证书监控（Certhub）也是一款集多个 HTTPS 站点安全检测、证书有效期管理以及异常告警等功能于一体的系统。它提供了可视化评级图表和可交互的跨品牌证书管理仪表盘，支持 HTTPS 安全评级，证书品牌、证书有效期、SSL 漏洞、PCI DSS & ATS 合规监控和集中管理；还能够及时地将异常情况通过邮件、短信进行告警通知，并根据评测结果，给出改造建议。"
    },
    "feature": {
      "title": "证书监控的特性",
      "options": [
        {
          "layout": "",
          "src": "/icon/certhub/features-1.svg",
          "content": "证书监控对您部署的 HTTPS 网站进行综合检测，我们根据安全风险给出了 A+、A、A-、B、C、D、E、F、T 九个评级，您可以非常直观地了解 HTTPS 站点的安全状况。同时，证书监控还会根据评测结果，给出合理的改造建议。"
        },
        {
          "layout": "end",
          "src": "/icon/certhub/features-2.svg",
          "title": "证书品牌",
          "content": "证书监控支持多种品牌证书的管理，只要您部署的是全球可信的证书品牌，例如 DigiCert、SecureSite、TrustAsia、Let's Encrypt 等，都可以在 SSL 证书监控上管理您的证书，避免因证书品牌而受限的困扰。"
        },
        {
          "layout": "",
          "src": "/icon/certhub/features-3.svg",
          "title": "证书有效期",
          "content": "证书监控针对不同有效期的证书，实施分级管理，可有效地解决大量不同有效期证书难以管理的难题。证书监控分级管理有效期包含3个月以上、3个月内、2个月内、一个月内和已过期，使您的证书有效期管理工作变得清晰明了。"
        },
        {
          "layout": "end",
          "src": "/icon/certhub/features-4.svg",
          "title": "证书类型",
          "content": "证书监控对证书进行分类管理，不仅从有效期和品牌两个方面，还可根据证书审核类型的不同对证书进行分类管理，包括 EV、OV、DV 和未知，全方位多维度的管理您部署的证书。"
        },
        {
          "layout": "",
          "src": "/icon/certhub/features-5.svg",
          "title": "SSL 漏洞",
          "content": "目前高危漏洞有8种，其中包括 DROWN 漏洞、FREAK 漏洞、OpenSSL Padding Oracle 攻击、Logjam 漏洞、OpenSSL CCS 注入漏洞、心血漏洞（Heartbleed）、POODLE 漏洞以及 CRIME 漏洞。证书监控对 SSL 协议上爆出的相关漏洞进行监控和统计，助力站点安全性，防患于未然。"
        },
        {
          "layout": "end",
          "src": "/icon/certhub/features-6.svg",
          "title": "合规监测",
          "content": "网站合法性是站点存在的前提，用户信任站点的基石。证书监控支持两个安全标准和规范监测站点的合规性：PCI DSS 第三方支付行业数据安全标准，以及 ATS 应用传输安全规范（PCI DSS 和 ATS 也被小程序、Android 等生态参考），协助您构建合规的 HTTPS 站点。"
        }
      ]
    },
    "scenes": {
      "title": "增强您的SSL证书管理",
      "content": "Certhub 按照设计思维，简化了证书生命周期的管理，方便用户统一监控管理，支持各个品牌证书的一键续费，降低运维成本，持续的监控证书状态，精确定位问题，异常情况实时告警，避免人工监控疏忽带来的安全风险。",
      "tabbarController": [
        {
          "index": "detect",
          "label": "检测"
        },
        {
          "index": "organize",
          "label": "管理"
        },
        {
          "index": "analyze",
          "label": "分析"
        }
      ],
      "panelData": {
        "detect": {
          "coverImgUrl": "/images/certhub-check.svg",
          "summary": "使用永久自动的网站扫描精准定位每一张证书已知或者未知的问题。",
          "list": [
            "一个扫描项目可以支持高达 10000个目标，可以扫描单个域名、域名列表、IP 地址所包含的所有 SSL 证书。",
            "找到包含子域名在内的更多的SSL证书。",
            "内网系统两步操作即可扫描所有 SSL 证书，敬请期待。"
          ]
        },
        "organize": {
          "coverImgUrl": "/images/bitcert-manage.svg",
          "summary": "管理集中于一处，分类、分组或者按照某些特定的条件分门别类的整理您的 SSL 证书。",
          "list": [
            "自动更新的特定永远让您得心应手。",
            "多种分类条件可以帮助您按需整理数据。",
            "创建自定义证书分组追踪可以为您节省时间。"
          ]
        },
        "analyze": {
          "coverImgUrl": "/images/bitcert-anly.svg",
          "summary": "无论您的证书签发机构是哪个，都可以从每张数字证书的证书详情了解证书更详细的运行情况.",
          "list": [
            "通过七个交互式图表评估整个蓝图，在一个屏幕上查看全部情况。",
            "根据直接发送到收件箱的自定义系统运行状况报告做出明智的决策。",
            "安排报告开始日期和频率，可以根据需要添加更多收件人。"
          ]
        }
      }
    },
    "footer": {
      "left": {
        "btn": "开始使用",
        "title": "开始使用Certhub证书监控",
        "summary": "按照我们的入门指南，只需点几次鼠标，即可快速入门证书监控。"
      },
      "right": {
        "btn": "联系我们",
        "title": "联系我们",
        "summary": "我们为您提供个性化的售前购买咨询服务，以及全面的技术售后服务。"
      }
    }
  },
  // "partner": {
  //   "pageTitle": "合作伙伴计划",
  //   "title": "Certbase 客户独享忠诚度折扣",
  //   "subtitle": "所有客户都有资格享受对应的折扣",
  //   "contents": [
  //     {
  //       "title": "",
  //       "content": [
  //         "您是电子商务建站公司、互联网服务商、服务器提供商、域名注册商或者专业从事数字证书销售的服务商家吗？您是否为您的客户提供IT服务或安全服务？我们是贵公司稳定的合作伙伴。当您购买多张证书时，我们会自动给您购买的产品打折扣，无需办理任何手续。您甚至都不用问我们就可以享受大量的折扣。",
  //         "Certbase™ 是全球 SSL 证书提供商之一，隶属于成都汇智云安信息科技公司。我们的目标是利用我们提供的各种服务来安全和保护网站安全。目前，我们提供域名级，企业级和增强级证书，通配符、多域名（SAN）、代码签名、文件签名、IP 地址证书和S/MIME邮件签名证书。我们是Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供最低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。感谢您选择我们公司，我们将继续改善我们的服务和质量。"
  //       ]
  //     },
  //     {
  //       "title": "合作伙伴计划基本信息",
  //       "content": [
  //         "自动获得高达 20% 的折扣",
  //         "起始折扣率在 5-10%",
  //         "如果您购买更多证书，还可以享受更高的折扣",
  //         "只销售市场上广泛信任的 SSL/TLS 证书产品",
  //         "活跃的合作伙伴还有额外奖励或者奖金"
  //       ]
  //     },
  //     {
  //       "title": "如何成为合作伙伴",
  //       "content": [
  //         "在汇智云安上订购任意数字证书的任何人都是我们的合作伙伴。合作伙伴折扣金额是根据客户帐户中有效（未过期）数字证书的总金额计算的。",
  //         "想咨询业务或者了解更多合作伙伴计划信息，请联系 sales@certbase.com。"
  //       ]
  //     }
  //   ],
  //   "range": {
  //     "title": "拉动滑块查看折扣比例以及对应的有效金额:",
  //     "amountTips": "价格以人民币计算:"
  //   },
  //   "discountTable": {
  //     "btn": "静悄悄的折扣表",
  //     "title": [
  //       "以人民币计价金额:",
  //       "折扣比例"
  //     ]
  //   }
  // },
  "contact": {
    "pageTitle": "联系我们",
    "title": "联系我们",
    "tel": "177-0811-9188",
    "phone": "400-800-3563",
    "email": "support@sslcertificateshop.com",
    "address": "成都市天府新区华府大道1号蓝润置地T3",
    "work": "周一到周日: 早9点 – 晚12点",
    "coverImgUrl": "/images/partner.svg",
    "summary": [
      "Certbase™ 是全球性的 SSL/TLS 证书提供商之一，隶属于成都汇智云安信息科技有限公司。我们的目标是利用我们提供的各种服务来保护网站安全。目前，我们提供域名级，企业级和增强级证书，通配符、多域名（SAN）、代码签名、文件签名、IP 地址证书和S/MIME邮件签名证书。从2019年开始，我们在全球销售我们自己的品牌Certbase证书。我们是Sectigo、GlobalSign、DigiCert、Certum 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供最低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。感谢您选择我们公司，我们将继续改善我们的服务和质量。"
    ],
    "group": {
      "title": "快捷方式",
      "options": [
        {
          "path": "/bank-details-and-payment-methods",
          "text": "支付方式"
        },
        {
          "path": "/about-us",
          "text": "关于我们"
        },
        {
          "path": "/client-side-encryption",
          "text": "客户端加密"
        },
        {
          "path": "/certbase-achievements-and-awards",
          "text": "荣誉墙"
        }
      ]
    },
    "contactForm": {
      "title": "联系我们",
      "btn": "提交",
      "subtitle": "想更多的了解我们的产品和服务，或者代理我们的产品，请联系我们！我们将尽快做出回复。",
      "tips": "同意成都汇智云安信息科技有限公司使用您的个人信息进行回复",
      "placehloder": {
        "contactName": "姓名",
        "email": "邮箱",
        "tel": "电话号码",
        "subject": "主题",
        "texarea": "您的问题、要求或建议描述"
      }
    }
  },
  "product": {
    "details": {
      "emailSigning": "邮件签名",
      "emailEncryption": "邮件加密",
      "documentSigning": "文件签名",
      "codeLabel": "公司名称",
      "emailLabel": "保护邮箱地址和加密邮件内容",
      "documentLabel": "次签名/年"
    },
    "recommendUse": [
      {
        "title": "推荐使用场景",
        "summary": [
          "The cheapest trusted TLS certificate from Thawte certification authority. It is issued in minutes thanks to quick DV vetting. It is great for securing common websites, encryption of sensitive data and for getting trust."
        ]
      }
    ],
    "recommendProduct": [
      {
        "title": "A certificate best-seller from $productName",
        "summary": [
          "The cheapest trusted TLS certificate from Thawte certification authority. It is issued in minutes thanks to quick DV vetting. It is great for securing common websites, encryption of sensitive data and for getting trust."
        ]
      }
    ],
    "pricePreview": {
      "btn": "了解更多",
      "title": "$productName价格表",
      "yearPrice": "年价格",
      "caPrice": "市场价",
      "codePriceSummary1": "价格远远低于市场价",
      "codePriceSummary2": "买两年单价比买一年更优惠",
      "codePriceSummary3": "买三年单价比买两年更优惠",
      "left": {
        "priceSummary": "价格远远低于市场价",
        "addSan": "增加域名价格表",
        "oneDomainPrice": "+ 1x 单域名 (标准域名): $sanPrice",
        "oneWildPrice": "+ 1x 通配符（泛域名）: $sanWildPrice"
      },
      "right": {
        "title": "采购多年更优惠",
        "imgUrl": "/images/mutil-chart-zh.svg"
      },
      "summary": [
        "$productName certificate supports both RSA and ECC keys.",
        "You can add other domains such as FQDN and Wildcard SANs to the certificate."
      ],
      "content": {
        "title": "订购多年期证书将为您节省很多钱！ 您最多可以订购6年期的 SSL 证书。您将每年收到一张新的证书。",
        "summary": [
          "省时省力，一个订单一次付款 = 更少的审批流程。",
          "订单有效期越长，折扣越高，意味着节省的费用越多。",
          "您将及时收到下一个年度的 SSL 证书，只需要在服务器上完成替换证书的操作并重启该服务即可。"
        ]
      }
    },
    "browerPreview": {
      "dv": {
        "title": "证书在浏览器中的显示效果",
        "summary": [
          "我们提供的所有TLS证书都是100％受信任的，并且网站访问者不会因浏览器错误而烦恼。\\n在Web浏览器中，使用小锁图标和URL中http旁边的字母“s”在地址栏中显示安全通信。\\n您可以通过单击小锁来检查证书所有者和颁发者。 在浏览器中了解更多有关TLS证书的信息。"
        ],
        "coverImgUrl": "/images/preview-dv.png"
      },
      "ov": {
        "title": "证书在浏览器中的显示效果",
        "summary": [
          "All the TLS certificates we offer are 100% trusted and website visitors are not bothered by browser errors.\\nIn a web browser, secure communication is shown in the address bar using the lock icon and the letter \"s\" next to the http in the URL.\\nYou can check the certificate owner and issuer by clicking on the lock. Learn moe about the TLS certificate in the browser."
        ],
        "coverImgUrl": "/images/preview-ov.png"
      },
      "ev": {
        "title": "证书在浏览器中的显示效果",
        "summary": [
          "All the TLS certificates we offer are 100% trusted and website visitors are not bothered by browser errors.\\nIn a web browser, secure communication using an EV certificate is shown in the address bar showing the green company name, the lock icon, and the letter \"s\" next to the http in the URL.\\nYou can check the certificate owner and issuer by clicking on the lock. Learn moe about the TLS certificate in the browser."
        ],
        "coverImgUrl": "/images/preview-ev.png"
      }
    },
    "premium": {
      "title": "$brand 高级特性"
    }
  },
  "brand": {
    "tableTitle": "我们提供的通配符 SSL/TLS 证书产品"
  },
  "privacy": {
    "pageTitle": "隐私政策",
    "title": "隐私政策",
    "subtitle": "我们如何收集和使用您的用户信息"
  },
  "terms": {
    "pageTitle": "服务条款",
    "title": "服务条款",
    "subtitle": "网站服务条款"
  },
  "refund": {
    "pageTitle": "退款政策",
    "title": "退款政策",
    "subtitle": "所有数字证书和网络安全解决方案都提供30天退款服务"
  },
  "member": {
    "pageTitle": "注册协议",
    "title": "注册协议",
    "subtitle": "注册协议内容"
  },
  "explainCert": {
    "banner": {
      "title": "什么是 SSL/TLS 证书?",
      "subtitle": "数字证书是互联网安全的支柱。",
      "summary": [
        "HTTPS（超文本传输安全协议）是一种互联网通信协议，可确保在用户的计算机与网站之间所传递的数据的完整性和机密性。每当访问网站时，用户都希望自己的在线体验安全无虞且具有私密性。SSL连接可保护在每次访问(称为会话)期间交换的敏感数据(例如信用卡信息) ,以防被非授权方拦截。",
        "使用 HTTPS 发送的数据可通过传输层安全协议 (TLS) 得到保护。该协议可提供三重关键保护：",
        "加密 - 对所交换的数据进行加密，以使其免受窥探。这意味着在用户浏览网站期间，没有人能够“听到”其会话内容，也无法在多个网页上跟踪其活动或窃取其信息。",
        "数据完整性 - 不管是有意还是无意，在数据传输期间数据都无法被修改或损坏，也不会被检测。",
        "身份验证 - 证明用户可与目标网站通信，这有助于保护用户免遭中间人攻击并建立用户信任，进而带来其他商业效益。"

      ],
      "imgUrl": "/images/explain-cert-banner.png"
    },
    "secureSocket": {
      "title": "如何创建安全连接",
      "subtitle": "SSL是建立加密链接的标准安全技术",
      "summary": [
        "其工作原理如下。称为\"SSL 握手\"的过程(最终用户不可见)在Web服务器和浏览器之间创建安全连接。三个密钥用于创建对称会话密钥，然后该对称会话密钥用于加密所有传输中的数据。"
      ],
      "imgUrl": "/images/explain-cert-banner.png",
      "list": [
        "服务器将其非对称公钥的副本发送到浏览器。",
        "浏览器创建对称会话密钥，并使用服务器的非对称公钥对其进行加密后再发送到服务器。",
        "服务器使用其非对称私钥解密加密的会话密钥，以获得对称会话密钥。",
        "服务器和浏览器现在使用对称会话密钥对所有传输的数据进行加密和解密。这允许使用安全通道，因为只有浏览器和服务器知晓对称会话密钥，并且会话密钥仅用于特定会话。如果浏览器第二天要连接到同一服务器，则会创建一个新的会话密钥。"
      ]
    },
    "whyUse": {
      "title": "为何您应该使用数字证书",
      "subtitle": "为您的网站访客和企业建立信任和在线安全性。",
      "list": [
        "对敏感数据进行加密",
        "激活HTTPS和锁定图标",
        "符合PCI标准",
        "证明合法性",
        "强化品牌识别",
        "提高SEO排名"
      ]
    },
    "contrast": {
      "title": "企业级(OV)、域名级(DV)、增强级(EV)证书的区别"
    },
    "effect": {
      "title": "增强级证书浏览器显示效果",
      "subtitle": "将您的公司名称，国家/地区代码和您的网址粘贴在下方的字段中，然后单击地址栏中的小锁符号查看您的公司名称和国家/地区代码。"
    },
    "advantage": {
      "title": "SSL/TLS 证书优势",
      "data": [
        {
          "title": "保护敏感信息",
          "icon": "/icon/advantage__product_message.svg",
          "summary": "采用 HTTPS 加密 APP 及网页通讯，防止数据在传送过程中被窃取、篡改，确保数据的完整性; 防止运营商的流量劫持、网页植入广告现象; 同时有效抵挡中间人的攻击，大大提升安全性。"
        },
        {
          "title": "提升转换率",
          "icon": "/icon/advantage__ conversion.svg",
          "summary": "使用 SSL/TLS 证书对整个网站进行安全加密，让访问者直观知道该网上部署了安全措施，增加访问者对网站的信任度，提高网络转化率。并且SSL证书可确保网站的所有流量的安全性。"
        },
        {
          "title": "浏览器显示安全",
          "icon": "/icon/advantage__brower.svg",
          "summary": "使用 SSL/TLS 证书对整个网站进行安全加密，浏览器地址栏直观的展示“安全挂锁”标识，让访问者知道该网站部署了安全措施，增加访问者对网站的信任度，并且SSL证书可确保网站的所有流量的安全性。"
        },
        {
          "title": "提升客户信任度",
          "icon": "/icon/advantage__4.svg",
          "summary": "已部署受信任的 SSL/TLS 证书，用户在访问域名时，Web 浏览器将会提供视觉提示，例如安全挂锁 或 绿色地址栏，提示访问者他们当前的网络行为受到保护，使访问者对网站加强信任，促进客户 端与服务端的商业合作。"
        },
        {
          "title": "安全防范网络钓鱼",
          "icon": "/icon/advantage__5.svg",
          "summary": "主流浏览器地址栏显示公司名称可以建立传输加密通道，保证信息传输的机密，展示网站真实身份，树立安全可信形象，全球网民都能通过浏览器，轻松辨别网站真伪，保障用户和企业的利益不受损害，增强用户信任。"
        },
        {
          "title": "保修保障",
          "icon": "/icon/advantage__6.svg",
          "summary": "SSL/TLS 证书保障是证书机构（CA）提供给申请人网站额外的保证，确保该网站可安全的进行网上业务。此保障应付申请人网站的访问者（依靠SSL 证书的人）因误发的 SSL 证书而承担从网上信用卡交易造成的直接损失。"
        }
      ]
    },
    "preview": {
      "title": "增强级证书浏览器显示效果",
      "subtitle": "将您的公司名称，国家/地区代码和您的网址粘贴在下方的字段中，然后单击地址栏中的小锁符号查看您的公司名称和国家/地区代码。",
      "placeholder": {
        "company": "公司名称",
        "country": "国家/地区代码",
        "website": "您的网址"
      },
      "body": {
        "title": "输入信息查看域名在浏览器上的显示效果",
        "tips": "然后单击地址栏中的小锁以查看公司名称和国家代码。"
      },
      "popover": {
        "title": "连接是安全的",
        "tips": "您发送给这个网站的信息（例如密码或信用卡号）不会外泄。",
        "statusTitle": "证书（有效）",
        "status": "颁发给："
      }
    }
  },
  "payment": {
    "pageTitle": "银行信息以及付款方式",
    "title": "银行信息以及付款方式",
    "subtitle": "我们接受信用卡、支付宝、微信支付、对公转账支付",
    "revolut": {
      "title": "对公收款账户以及开票信息",
      "summary": [
        "该银行账户只接受公对公转账款项，转帐以后请联系企业微信、在线客服、热线电话或者邮箱告知转账信息。"
      ],
      "list": [
        // {
        //   "label": "账户名称:",
        //   "value": "成都汇智云安信息科技有限公司"
        // },
        // {
        //   "label": "开户银行:",
        //   "value": "招商银行股份有限公司成都天府大道支行"
        // },
        // {
        //   "label": "银行帐号:",
        //   "value": "128906670410802"
        // },
        // {
        //   "label": "电话号码:",
        //   "value": "4001280988"
        // },
        // {
        //   "label": "统一社会信用代码:",
        //   "value": "91510100MA61R0XJ15"
        // },
        // {
        //   "label": "注册地址:",
        //   "value": "中国（四川）自由贸易试验区成都高新区世纪城南路599号天府软件园D区6栋505号"
        // }
      ]
    },
    "methods": [
      {
        "layout": "end",
        "title": "支付宝",
        "imgUrl": "/images/alipay.svg",
        "summary": [
          "感谢支付宝提供快捷支付方式"
        ]
      },
      {
        "layout": "end",
        "title": "微信支付",
        "imgUrl": "/images/wechatpay.svg",
        "summary": [
          "感谢微信支付提供快捷支付方式"
        ]
      },
      {
        "layout": "end",
        "title": "信用卡",
        "imgUrl": "/images/creditcard.png",
        "summary": [
          "接受卡种包括：Visa、Mastercard、 American Express、Discover和 JCB"
        ]
      }
    ]
  },
  "references": {
    "pageTitle": "这些伟大的客户",
    "title": "我们的客户",
    "customers": [
      "ALE International",
      "MANN+HUMMEL",
      "东忠集团",
      "西格玛",
      "东软集团",
      "大族激光",
      "强生",
      "利勃海尔"
    ],
    "summary": [
      "六年以来，Certbase 一直为国内主要公司、银行、公共管理和电商网站提供SSL/TLS证书。 我们以专业的背景为客户提供解决网站安全问题的方案，并以多种语言提供高质量的技术支持。在解决客户问题时，我们不会因为客户的规模不一样而提供不一样的服务，我们也为想要确保简单的网站安全的客户提供全面支持。",
      "Certbase 是全球 SSL/TLS 证书的大型发行商之一，隶属于成都汇智云安信息科技有限公司。我们的使命是建立信任的在线网络，因为我们充分认识到：只有建立一个可信的互联网，才能发挥其巨大潜能为人类沟通和商业活动造福，Certbase 在全力以赴的去帮助个人、网商、中小企业和大企业建立在线信任。目前，我们提供域名级（DV），企业级（OV）和增强级（EV）SSL/TLS 证书，通配符（Wildcard）、多域名（SAN）、代码签名（Code Signing）、文件签名（Document Signing）、IP 地址证书和S/MIME邮件签名证书。我们是Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供最低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。我们已经为数千计的大客户提供了数十万张证书。 以下列出的仅为其中比较知名的客户。"
    ],
    "group": [
      {
        "groupName": "商业公司",
        "options": [
          "中国联通",
          "一一五",
          "爱奇艺",
          "熊猫 TV",
          "中国电信",
          "福建晋华"
        ]
      },
      {
        "groupName": "银行、金融和保险公司",
        "options": [
          "农商银行",
          "票据交易所",
          "背兜金科",
          "雷根证券",
          "招商金科",
          "仁和人寿"
        ]
      }
    ]
  }
}

<template>
  <div class="footer" :class="`footer--${$store.state.navbarClassStyle}`">
    <div class="container footer-top flex flex-between">
      <div
        v-for="(item, index) in $t('footer').media"
        :key="`footer-media-${index}`"
        class="footer-media flex">
        <CImg class="footer-media-icon" :src="item.icon"/>
        <div class="media-content">
          <h4 class="media-title">{{ item.title }}</h4>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="container footer-middle flex flex-start">
      <div class="footer-aside">
        <Logo :white="true"/>
        <ul class="footer-aside-group footer-aside-group-top">
          <li
            v-for="(item, index) in $t('footer').aside"
            :key="`footer-aside-item-${index}`"
          >
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </li>
        </ul>
        <!-- <img class="site-seal" src="/images/globalsign-site-seal.svg" alt=""> -->
      </div>
      <div class="footer-body">
        <FooterGroup/>
      </div>
      <ul class="footer-aside-group footer-aside-group-bottom">
        <li
          v-for="(item, index) in $t('footer').aside"
          :key="`footer-aside-item-${index}`"
        >
          <i :class="item.icon"></i>
          <span>{{ item.label }}</span>
        </li>
      </ul>
    </div>
    <!-- brans tags list -->
    <!-- <div class="footer-body-append container">
      <div
        v-for="signature in $t('footer').signature"
        :key="signature.coverImgUrl"
        class="sponsors-item">
        <img
          :src="signature.coverImgUrl" alt=""
          @click="checkSignature(signature)"
          :draggable="false"
        />
      </div>
    </div> -->
    <!-- <div class="footer-body-append container">
      <div
        v-for="logo in brandsLogo"
        :key="logo.coverImgUrl"
        class="sponsors-item"
        :class="`sponsors-item-${logo.link.substr(1)}`">
        <a :href="`/brand${logo.link}`">
          <img
            :class="`brand-logo-${logo.link.substr(1)}`"
            :src="logo.coverImgUrl" alt=""
            :draggable="false"
          />
        </a>
      </div>
    </div> -->
    <!-- <section class="footer-body-append disclaimer container">
      {{ $t('footer').declareContent.content }}
    </section> -->
    <div class="footer-bottom">
      <div class="container">
        <div class="flex flex-between">
          <div class="bottom-left">
            <p
              v-for="(item, index) in $t('footer').copyright"
              :key="`footer-copyright-item-${index}`"
              v-html="formatCopyright(item)">
            </p>
          </div>
          <div class="bottom-right">
            <router-link
              v-for="(item, index) in $t('footer').license"
              :key="`footer-license-item-${index}`"
              :to="item.path">
              {{ item.text }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import FooterGroup from './FooterGroup.vue'
// import FooterGroup from './FooterGroupNew.vue'
import FooterGroup from './FooterGroupGen.vue'

const brandsLogo = {
  alphassl: {
    coverImgUrl: '/logo/alphassl-g.svg',
    link: '/alphassl'
  },
  bitcert: {
    coverImgUrl: '/logo/bitcert-g.svg',
    link: '/bitcert'
  },
  digicert: {
    coverImgUrl: '/logo/digicert-g.svg',
    link: '/digicert'
  },
  geotrust: {
    coverImgUrl: '/logo/geotrust-g.svg',
    link: '/geotrust'
  },
  globalsign: {
    coverImgUrl: '/logo/globalsign-g.svg',
    link: '/globalsign'
  },
  rapidssl: {
    coverImgUrl: '/logo/rapidssl-g.svg',
    link: '/rapidssl'
  },
  sectigo: {
    coverImgUrl: '/logo/sectigo-g.svg',
    link: '/sectigo'
  },
  thawte: {
    coverImgUrl: '/logo/thawte-g.svg',
    link: '/thawte'
  },
  positivessl: {
    coverImgUrl: '/logo/positivessl-g.svg',
    link: '/positivessl'
  }
}

/* eslint-disable */
const sponsors = [
  {
    coverImgUrl: require('@/assets/sponsors/entrustdatacard.svg'),
    signature: `https://www.sslcertificateshop.com/templates/SSLCertificateShop/assets/img/authorizedcertificate.pdf,'',width=800,height=600,left=150,top=150,resizable=no,scrollbars=no,status=yes,toolbar=no,location=no,menubar=no,menu=yes`
  },
  {
    coverImgUrl: require('@/assets/sponsors/digicertcertifiedpartner.svg'),
    signature: `https://seal.digicert.com/seals/popup/popup.php?acct_id=114314&tag=M-bWGgHv&url=www.sslcertificateshop.com&lang=en_US&cbr=1498636084265,,width=515,height=530,left=150,top=150,resizable=no,scrollbars=no,status=yes,toolbar=no,location=no,menubar=no,menu=yes`
  },
  {
    coverImgUrl: require('@/assets/sponsors/globalsignpartner.svg'),
    signature: `https://www.globalsign.cn,,width=515,height=530,left=150,top=150,resizable=no,scrollbars=no,status=yes,toolbar=no,location=no,menubar=no,menu=yes`
  },
  {
    coverImgUrl: require('@/assets/sponsors/nortonsecured.svg'),
    signature: `https://trustsealinfo.websecurity.norton.com/splash?form_file=fdf/splash.fdf&dn=www.sslcertificateshop.com&lang=zh_cn,'',width=515,height=530,left=150,top=150,resizable=no,scrollbars=no,status=yes,toolbar=no,location=no,menubar=no,menu=yes`
  }
]
/* eslint-disable */

const formatCopyright = (str) => {
  return str.replace(/\$year/, new Date().getFullYear().toString())
}

const checkSignature = (sponsor) => {
  let params = [...sponsor.signature.split(',')]
  let sizeParams = params.slice(2).toString()
  window.open(params[0], params[1], sizeParams)
}

export default {
  name: 'Footer',
  components: {
    FooterGroup
  },
  data () {
    return {
      sponsors,
      brandsLogo
    }
  },
  methods: {
    formatCopyright,
    checkSignature
  }
}
</script>

<style lang="less" scoped>
// .footer.footer--all-view,
// .footer.footer--transparent {
.footer {
  color: #fff;
  // background-color: #1159a9fc;
  background-color: #262c3b;
  .media-title,
  .media-title ~ p {
    // color: @border-color;
    color: #767D8E;
  }
  .footer-bottom,
  .footer-top,
  .footer-body {
    border-color: fade(#fff, 15);
  }
  & /deep/ .item a {
    // color: @border-color;
    color: #767D8E;
    &:hover {
      color: #fff;
    }
  }
  .footer-bottom {
    // background-color: #1b448e;
    background-color: #1f2430;
    a:hover {
      opacity: .5;
      color: #fff;
    }
  }
}

.disclaimer {
  color: #767D8E;
}

.footer-top,
.footer-bottom {
  padding: 24px 0;
  a, p {
    color: #767D8E;
  }
}
.footer-bottom {
  // border-top: @border;
  // & /deep/ .flex {
  //   align-items: flex-end;
  // }
  .bottom-left p:last-child {
    font-size: 12px;
    opacity: .5;
    margin-top: 6px;
  }
  a {
    margin-left: 12px;
    transition: .4s ease;
  }
  a:hover {
    color: @theme-color;
  }
}
.footer-middle {
  padding: 48px 0;
}
.footer-top {
  border-bottom: @border;
}
.footer-aside {
  width: 242px;
  .site-seal {
    margin-top: 24px;
    width: 150px;
  }
}
.footer-body {
  padding-left: 48px;
  width: calc(100% - 242px);
  border-left: @border;
}
.footer-media {
  .footer-media-icon {
    width: 64px;
  }
}
.media-content {
  margin-left: 12px;
  // * {
  //   color: #767D8E;
  // }
}
.media-title {
  margin-bottom: 6px;
  font-size: 18px;
}
.media-title ~ p {
  color: @text-color-l;
}

.footer-aside-group {
  margin-top: 12px;
  li {
    display: flex;
    // align-items: flex-start;
    align-items: center;
    padding: 6px 0;
    color: #767D8E;
  }
  [class*="ri-"] {
    margin-right: 6px;
  }
  &.footer-aside-group-bottom {
    display: none;
  }
}

.footer-body-append {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-top: 24px;
  border-top: 1px solid fade(#fff, 15);
}
.sponsors-item {
  // width: 180px;
  width: calc(100% / 9);
  margin-right: 12px;
  &.sponsors-item-geotrust {
    margin-right: 12px;
  }
  img {
    width: 100%;
    height: 36px;
    cursor: pointer;
    filter: brightness(120%);
    display: block;
  }
  // img.brand-logo-geotrust {
  //   transform: scale(1.5);
  //   margin-right: 12px;
  // }
  // &:nth-child(4) {
  //   width: 90px;
  //   margin-right: 0;
  // }
}

@media screen and (max-width: 990px){
  .footer-middle.flex{
    flex-direction: column;
  }
  .footer-aside-group-top {
    display: none;
  }
  .footer-aside-group.footer-aside-group-bottom {
    display: block;
  }
  .footer-bottom .flex {
    text-align: center;
    align-items: center;
    flex-direction: column-reverse;
    .bottom-right {
      margin-bottom: 24px;
    }
  }
  .footer-aside {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
  .footer-body {
    border-left: unset;
    width: 100%;
    padding-left: 0;
  }
  .footer-middle {
    padding: 24px 0;
  }
}
@media screen and (max-width: 768px){
  .footer-body-append {
    border-top: unset;
    display: none;
  }
  .footer-bottom {
    border-top: unset;
    padding-top: 24px;
  }
  .footer-top {
    display: none;
  }
  .footer.footer--transparent {
    & /deep/ .item {
      // border-bottom-color: fade(#fff, 10);
      border-bottom-color: fade(#fff, 10);
    }
    & /deep/ .title {
      border-bottom-color: fade(#fff, 30);
    }
  }
}
</style>

<template>
  <div class="help-media-left">
    <div class="help-media__col">
      <h4 class="help-col__title help-contents__title">{{ $t('help').navbar.left.contents.title }}</h4>
      <ul>
        <li
          v-for="item in $t('help').navbar.left.contents.options"
          :key="item.link">
          <a :href="item.link">{{ item.label }}</a>
        </li>
      </ul>
    </div>
    <div class="help-media__col">
      <h4 class="help-col__title">{{ $t('help').navbar.left.other.title }}</h4>
      <ul>
        <li
          v-for="item in $t('help').navbar.left.other.options"
          :key="item.link"
          class="flex help-other-item">
          <img :src="item.icon" alt="" class="help-item__icon">
          <div class="help-other__body">
            <h4 class="">{{ item.title }}</h4>
            <a :href="item.link">{{ item.label }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpMediaLeft'
}
</script>

<style lang="less" scoped>
.help-media-left {
  display: flex;
}
.help-media__col ~ .help-media__col {
  margin-left: 48px;
}
.help-media__col {
  .help-col__title {
    margin-bottom: 18px;
  }
  .help-contents__title {
    margin-bottom: 6px;
  }
  .help-item__icon {
    width: 32px;
    margin-right: 6px;
  }
  li > a {
    display: inline-block;
    padding: 12px 0;
    border-bottom: 1px solid transparent;
    position: relative;
  }
  li > a::before {
    content: " ";
    width: 0;
    height: 2px;
    background-color: @theme-color;
    transition: .4s ease;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  li > a:hover::before {
    width: 100%;
  }
}
.help-other-item:not(:last-child) {
  margin-bottom: 12px;
}
.help-other__body {
  > a {
    padding: 6px 0;
    display: inline-block;
    position: relative;
  }
  > a::before {
    content: " ";
    width: 0;
    height: 2px;
    background-color: @theme-color;
    transition: .4s ease;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  > a:hover::before {
    width: 100%;
  }
}
</style>

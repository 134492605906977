/**
 * Default request init
 */
import VueCookie from 'vue-cookie'
import { Store } from 'vuex'
// import api from './index'
import { getProducts, getTypes, getBrands } from './product'

// Format navbar data
export function formatNavbar (data) {
  const brandName = [ // securesitepro securesite
    'digicert', 'globalsign', 'certum', 'securesite', 'geotrust', 'sectigo',
   'rapidssl', 'alphassl', 'securesitepro', 'cfca'
  ]
  // const brandName = [ // securesitepro securesite
  //   'digicert', 'globalsign', 'certum', 'securesite', 'geotrust', 'sectigo', 'thawte',
  //   'bitcert', 'rapidssl', 'alphassl', 'securesitepro', 'positivessl', 'cfca'
  // ]
  const res = [
    {
      brand: 'DigiCert',
      brandPath: '/digicert',
      options: []
    },
    {
      brand: 'Certum',
      brandPath: '/certum',
      options: []
    },
    {
      brand: 'GlobalSign',
      brandPath: '/globalsign',
      options: []
    },
    {
      brand: 'Securesite',
      brandPath: '/securesite',
      options: []
    },
    {
      brand: 'Geotrust',
      brandPath: '/geotrust',
      options: []
    },
    {
      brand: 'Sectigo',
      brandPath: '/sectigo',
      options: []
    },
    {
      brand: 'RapidSSL',
      brandPath: '/rapidssl',
      options: []
    },
    {
      brand: 'AlphaSSL',
      brandPath: '/alphassl',
      options: []
    },
    {
      brand: 'Secure Site Pro',
      brandPath: '/securesitepro',
      options: []
    },
    {
      brand: 'CFCA',
      brandPath: '/cfca',
      options: []
    }
  ]
  // 1.securesite  2.globalsign  3.geotrust
  data.forEach(item => {
    const { subBrand } = item
    const lowSubBrand = (subBrand || '').replace(/[\s]/g, '').toLowerCase()
    if (lowSubBrand && !brandName.includes(lowSubBrand)) {
      brandName.push(subBrand || '')
      res.push({
        brand: subBrand,
        brandPath: `/${lowSubBrand}`,
      options: []
      })
    }

    // Add data in res
    res.forEach(nav => {
      if ((nav.brand?.replace(/[\s]/g, ''))?.toLowerCase() === lowSubBrand) {
        nav.options.push(item)
      }
    })
  })
  return res
}

/**
 * code_signing 代码签名
 * ev_code_signing EV代码签名
 * document_signing_2000 文件签名
 * smime_basic 邮件签名证书
 * smime_premium 高级邮件签名证书
 * ip IP证书
 */
// 获取数字证书数据
export function formatCertData (data) {
  const res = []
  const types = [
    // 'ip',
    'code-signing',
    'ev-code-signing',
    'email-signing', // smime_basic & smime_premium
    'document-signing'
  ]
  types.forEach(t => {
    res.push({
      type: t,
      tyepPath: `/${t}`,
      options: [],
      title: ''
    })
  })
  data.forEach(item => {
    const { sslType: type } = item
    // Add data in res
    res.forEach(nav => {
      // const typeReg = RegExp(nav.type + '')
      // if (typeReg.test(type + '')) {
      if (nav.type === type) {
        nav.options.push(item)
      }
    })
  })
  return res
}

// 单域名
export function formatSingleCert (data) {
  const singleCerts = data.filter(item => /_one_/.test(item.sslType + ''))
  return formatNavbar(singleCerts)
}

export class InitRequest {
  constructor (store, language = 'zh') {
    this.store = store
    this.language = language
    this.language = VueCookie.get('locale')
  }

  start () {
    getTypes(this.language).then(res => {
      this.store.commit('getAllTypes', res)
    })
    getProducts(this.language).then(res => {
      this.store.commit('getAllProducts', res)
      this.store.commit('genNavbar', formatNavbar((res)))
      this.store.commit('genNavbarCertData', formatCertData((res)))
      this.store.commit('genNavbarSingleData', formatSingleCert((res)))
    })
  }
}
export default InitRequest

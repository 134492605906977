<template>
  <div class="compliance-left">
    <div class="compliance-menu">
      <ul
        v-for="(row, rowIndex) in menu"
        :key="`compliance-menu-row-${rowIndex}`"
      >
        <li
          v-for="(col, colIndex) in row"
          :key="`compliance-menu-col-${colIndex}`"
        >
          <a :href="col.path">{{ col.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const menu = [
  [
    { label: '等保云', path: '/compliance' },
    { label: '等保认证', path: '/compliance-auth' },
    { label: '公有云', path: '/public-cloud' },
    { label: 'LEI认证', path: '/lei-auth' },
    { label: '私有PKI', path: '/private-pki' }
  ],
  [
    { label: '电子合同', path: '/contract' },
    { label: '物联网证书', path: '/aot-tls' },
    { label: '可信认证联盟', path: '/tca' },
    { label: '大批量S/MIME管理方案', path: '/s-mime-solutions' }
  ]
]
export default {
  name: 'ComplianceLeft',
  data() {
    return {
      menu
    }
  }
}
</script>
<style lang="less" scoped>
.compliance-menu {
  display: flex;
}
li {
  // text-align: right;
  padding-right: 48px;
  > a {
    display: inline-block;
    padding: 6px 0;
    border-bottom: 1px solid transparent;
    position: relative;
  }
  > a::before {
    content: " ";
    width: 0;
    height: 2px;
    background-color: @theme-color;
    transition: .4s ease;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  > a:hover::before {
    width: 100%;
  }
}
li:not(:last-child) {
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .compliance-menu {
    display: block;
    ul:first-child {
      margin-bottom: 18px;
    }
  }
}
</style>
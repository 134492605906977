export default {
  "parseCert": {
    "pageTitle": "解析证书",
    "title": "解析证书",
    "subtitle": "",
    "btn": "解析证书",
    "placeholder": "在此处粘贴您 PEM 的格式证书",
    "summary": [
      "证书解析与查看，可以通过上传ECC、RSA、SM2证书文件或粘贴BASE64编码查看证书的主题信息，签发者信息和证书的详细信息。",
      "certbase.com 提供的证书工具供所有用户免费使用，Certbase 不记录任何信息，用户在使用证书工具中、使用证书工具后产生的任何错误或造成任何损失，Certbase 不承担任何责任。"
    ]
  }
}

<template>
  <div class="com-navbar-container">
    <div
      :class="[
        { 'com-navbar--fixed': $store.state.navbarFixed },
        `com-navbar-${$i18n.locale}`
      ]"
      class="com-navbar"
      @mouseleave="closeExpand()">
      <input type="text" id="expand-input"/>
      <div class="container flex flex-between">
        <div class="nav-options flex">
          <a href="/" class="logo-container" @mouseenter="closeExpand()">
            <!-- <Logo :white="isWhiteLogo($store, $route.path)"/> -->
            <Logo :white="isWhiteLogo($store, $route.path)"/>
          </a>
          <ul class="flex">
            <li
              v-for="(item, index) in $t('navbar').options"
              :key="`com-navbar-item-${index}`"
              class="flex navbar-item"
              @click="showExpand({ value: item.expand })">
              <label
                v-if="item.expand"
                class="flex navbar-item-expand"
                :class="{ active: currentItem === item.expand }"
                for="expand-input">
                {{ item.text }}
                <i class="ri-arrow-down-s-line"></i>
              </label>
              <router-link
                v-if="!item.expand"
                :to="item.path"
                :target="/^(http)/.test(item.path) ? '_blank' : '_self'"
                :class="{ 'router-link-exact-active': $route.path === item.path || $route.path === `/en${item.path}` }"
                @click.prevent="forword(item.path)">
                {{ item.text }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="flex">
          <a href="" class="flex right-item" @click.prevent="showExpand({ value: 'contact' })">
            {{ $t('navbar').contactUs }}
            <i class="ri-arrow-down-s-line"></i>
          </a>
          <router-link to="/help" class="right-item">
            {{ $t('navbar').help }}
          </router-link>
          <!-- <ChangeLocal class="right-item" :type="type"/> -->
          <a href="https://app.certbase.com/sign-in" class="right-item">
            {{ $t('navbar').login }}
          </a>
          <a class="right-item signup-btn" href="https://app.certbase.com/sign-up">立即注册</a>
          <!-- <el-popover
            trigger="hover"
            popper-class="local-popover">
            <a slot="reference" href="https://app.certbase.com/sign-in" class="flex right-item">
              {{ $t('navbar').login }}
              <i class="ri-arrow-down-s-line"></i>
            </a>
            <a class="change-language-item" href="https://app.certbase.com/sign-up">{{ $t('navbar').registe }}</a>
          </el-popover> -->
        </div>
      </div>
      <ComNavbarExpand
        v-show="expand"
        class="com-navbar-expand animate__animated"
        :class="{
          'animate__fadeIn': isFadeIn,
          'animate__fadeOut': !isFadeIn
        }"
        @close="closeExpand()"
        :mode="expandMode"
      />
      <!-- dev compliance -->
      <!-- <ComNavbarExpand
        class="com-navbar-expand animate__animated"
        mode="contact"
      /> -->
    </div>
  </div>
</template>

<script>
import ComNavbarExpand from './ComNavbarExpandNew.vue'
import ChangeLocal from '../ChangeLocal.vue'
import { computed, onUnmounted, ref, watch } from '@vue/composition-api'

// Open contact window
function openContact () {
  window.open('https://drift.me/base', '', 'width=800,height=700')
}
// Is white logo
function isWhiteLogo($store, path) {
  return $store.state.navbarClassStyle === 'default'
}

// Computer navigation
export default {
  name: 'ComNavbar',
  components: {
    ChangeLocal,
    ComNavbarExpand
    // ComNavbarExpandFloat
  },
  props: {
    mode: String
  },
  setup (props, { root }) {
    const expand = ref(false)
    const isFadeIn = ref(false)
    const currentItem = ref('') // 当前expand选项索引
    let timer = 0
    const language = ref('zh')
    const expandMode = ref('default') // default || ssl
    const type = computed(() => {
      // const isWhitePath = root.$route.path === '/' || root.$route.path === '/certhub'
      const { transparentPaths } = root.$store.state
      const isWhitePath = transparentPaths.includes(root.$route.path)
      return !root.$store.state.navbarFixed && isWhitePath ? '' : 'primary'
    })

    const locale = computed(() => root.$store.state.locale)

    const closeExpand = () => {
      currentItem.value = ''
      isFadeIn.value = false
      timer = setTimeout(() => {
        expand.value = false
        clearTimeout(timer)
      }, 300)
    }

    const showExpand = (item) => {
      clearTimeout(timer)
      expand.value = false
      const whites = ['ssl', 'cert', 'certhub', 'monitor', 'partner', 'help', 'compliance', 'contact']
      // Change expand mode about ssl证书 || 数字证书（default）
      // expandMode.value = item.value === 'ssl' ? 'ssl' : 'default'
      if (item.value === currentItem.value) {
        closeExpand()
        return
      }
      expandMode.value = item.value
      if (whites.includes(item.value)) {
        isFadeIn.value = true
        expand.value = true
      } else {
        closeExpand()
      }
      if (item.value === 'ssl') {
        // Show ssl data
      }
      currentItem.value = item.value
    }

    watch(() => root.$route.path, () => {
      if (expand.value) {
        closeExpand()
      }
    })

    onUnmounted(() => {
      clearTimeout(timer)
    })

    const forword = (path) => {
      if (/^(http)/.test(path)) {
        window.open(path)
        return
      }
      window.location.href = path
    }

    return {
      type,
      locale,
      expand,
      forword,
      isFadeIn,
      language,
      expandMode,
      showExpand,
      closeExpand,
      openContact,
      currentItem,
      isWhiteLogo
    }
  },
  methods: {
    // Change language
    handleChangeLanguage (language) {
      this.$i18n.locale = language // eslint-disable-line
    }
  }
}
</script>
<style lang="less">
.com-navbar {
  box-shadow: 0 0 6px rgba(0, 0, 0, .1);
}
.telephone-number-link {
  display: block;
  width: 100%;
  text-align: center;
}
.telephone-number-link:hover {
  color: @theme-color;
}
</style>
<style lang="less" scoped>
// .com-navbar-container {
//   height: 60px;
// }
.com-navbar {
  width: 100%;
  // color: #fff;
  color: initial;
  position: relative;
  // background-color: @theme-color;
  background-color: #fff;
  transition: .3s ease;
  z-index: 9999;
  transition: .4s ease;
  position: fixed;
  top: 0;
  left: 0;
  // border-bottom: 1px solid transparent;
  &.com-navbar--fixed {
    color: initial;
    background-color: #fff;
    border-bottom: 1px solid fade(@border-color, 50);
  }
}

.signup-btn {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.002em;
  color: #ffffff;
  background-color: #1664ff;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}
.navbar-item-expand {
  position: relative;
  .ri-arrow-down-s-line {
    transition: .3s ease;
  }
  &.active {
    .ri-arrow-down-s-line {
      transform: rotate(-180deg);
    }
  }
  // &.active::before {
  //   opacity: 1;
  //   bottom: -27.5px;
  // }
  // &::before {
  //   content: " ";
  //   width: 15px;
  //   height: 15px;
  //   opacity: 0;
  //   background: #f3f3f3;
  //   position: absolute;
  //   bottom: -50px;
  //   left: 50%;
  //   transform: translateX(-50%) rotate(45deg);
  //   transition: .3s ease;
  // }
}
.telephone {
  color: #fff;
  margin-right: 1em;
  img {
    width: 24px;
    height: 24px;
    // width: 19px;
    // height: 19px;
  }
  &.telephone--primary {
    color: #7D8C9C;
    [class*="ri-phone"] {
      color: #7D8C9C;
    }
  }
}
.contact-us-btn {
  margin-right: 1em;
  cursor: pointer;
  transform: translateY(1px);
  img {
    width: 28px;
    height: 28px;
  }
}
[class*="ri-phone"] {
  margin-right: 3px;
  font-size: 24px;
  color: #fff;
}
.com-navbar--fixed {
  .telephone,
  [class*="ri-phone"] {
    color: #7D8C9C;
  }
}
.com-navbar--expand {
  background-color: #fff;
  color: @theme-color;
}
.right-item {
  margin-left: 24px;
}
.login-btn--user {
  [class*="ri-user"] {
    font-size: 22px;
  }
  .icon-user,
  img {
    width: 24px;
    height: 24px;
    transform: translateY(1px);
  }
}
.logo-container {
  margin-right: 24px;
}
.nav-options {
  ul {
    // margin-right: 12px;
    margin: 0;
  }
  li {
    padding: 6px 12px;
    height: 60px;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    a {
      display: inline-block;
      height: calc(60px - 12px);
      vertical-align: middle;
      line-height: calc(60px - 12px);
    }
    * {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
    }
  }
  [class*="ri-arrow"] {
    margin-left: 3px;
  }
}
.com-navbar-zh {
  .nav-options li * {
    font-size: 15px;
  }
}
// Expand
.com-navbar-expand {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: #f3f3f3;
  animation-duration: .3s;
  animation-delay: 0;
}
#expand-input {
  position: fixed;
  top: -9999px;
  z-index: -9;
}

.logo-container{
  &::before {
    display: none;
  }
}
.router-link-exact-active {
  &::before {
    content: " ";
    position: absolute;
    height: 2px;
    width: calc(100% - 24px);
    // background-color: @theme-color;
    bottom: 0;
  }
}

.change-language-item {
  white-space: nowrap;
  padding: 6px 12px;
  cursor: pointer;
  display: block;
  &:hover {
    background-color: fade(@theme-color, 10);
  }
}
.change-language-item:first-child {
  padding-top: 12px;
}
.change-language-item:last-child {
  padding-bottom: 12px;
}
</style>

/**
 * 证书日志查询 中文版
 * 页面路由 https://certbase.com/certhub/log
 */

 // Banner
 const banner = {
  title: '证书透明度日志',
  summary: '证书透明度是用于监视SSL证书的一个开源框架。域名持有者可以通过证书透明度，去监视其域内证书颁发的情况，并判断证书是否被错误签发。证书透明度框架能够迅速、有效地识别被误发的证书，而在过去的系统中，欺诈证书可能被忽视长达数周或数月之久，造成严重破坏，直至被发现',
  link: 'https://app.certbase.com/sign-up',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_log.svg'
}

// 证书日志查询说明
const manage = {
  title: '证书透明度日志查询',
  options: [
    {
      title: '方便快捷的查询',
      summary: '输入任意域名即可查询该域名自证书透明度日志上线以来签发的所有的 SSL/TLS 证书'
    },
    {
      title: '基于日志的续费',
      summary: '凡是可以查询到的SSL/TLS证书日志，都可以通过快速续费按钮提交新的证书订单以完成续费操作'
    },
    {
      title: '防止钓鱼网站',
      summary: '独特的云端日志监控工具，可以快速帮企业识别、发现任何形式的钓鱼域名，方便企业及时处置钓鱼网站'
    }
  ]
}

// 特性
const features = {
  title: '透明度日志特性',
  options: [
    [
      // 若是图片填充填写图片URL 若不是保留空值
      { title: '仅允许附加', content: ['杜绝在证书对域名持有者无法显示的情况下，由数字证书认证机构向域名颁发SSL证书，或至少大大增加了这种行为的难度。'], img: '' }, // 左
      { title: '公开透明', content: ['提供一个公开的审计和监视系统，使域名持有者或数字证书认证机构可以判断证书颁发过程是否存在不当或恶意颁发行为。'], img: '' } // 右
    ],
    [
      { title: '钓鱼网站提醒', content: ['保护用户免遭不当或恶意颁发证书的欺诈。'], img: '' },
      { title: '加密可靠', content: ['证书日志使用知名的“Merkle Tree Hashes”加密机制来防止被篡改。'], img: '' }
    ],
    [
      { title: '公开审计', content: ['任何人都可以查询日志，或者验证颁发的数字证书是否已经被合理地记录在了日志之中。'], img: '' },
      { title: '仅允许附加', content: ['证书记录只能被添加，而不能被删除，修改，或者追溯地将数据插入日志。'], img: '' }
    ]
  ]
}

// 步骤
const step = {
  title: '快速体验日志查询',
  link: 'https://app.certbase.com/sign-up', // 按钮点击前往地址
  linkText: '立即体验',
  options: [
    {
      img: '',
      summary: '注册账户'
    },
    {
      img: '',
      summary: '输入域名'
    },
    {
      img: '',
      summary: '立即查询'
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}
